<template>
  <div class="d-flex flex-column">
    <LandingSection />
    <ActionProducts />
    <WhyBuyFromUs />
    <OurWorkSection />
    <QuotesSection />
    <OurPartners />
    <ContactUsSection />
  </div>
</template>

<script>
import LandingSection from './landing/LandingSection.vue';
import ActionProducts from './actions/ActionProducts.vue';
import WhyBuyFromUs from './WhyBuyFromUs.vue';
import OurWorkSection from './ourwork/OurWorkSection.vue';
import QuotesSection from './quotes/QuotesSection.vue';
import OurPartners from './partners/OurPartners.vue';
import ContactUsSection from './ContactUsSection.vue';

export default {
  name: "Home",
  components: { LandingSection, ActionProducts, WhyBuyFromUs, OurWorkSection, QuotesSection, OurPartners, ContactUsSection },
};
</script>
