<template>
    <div class="mobile-side-navbar">
        <BaseBackdrop @click="closeMobileNav" :topPosition="'70'" class="backdrop" :class="{'backdrop-visible': isVisible}" />
        <div class="mobile-nav" :class="{'slide-open-mobile-navbar': isVisible}">
            <ul class="main-menu">
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'/'">
                        Pocetna strana
                    </RouterLink>
                </li>
                <li class="d-flex justify-content-between" :class="{'light-gray': showCategories}">
                    <div class="menu-item">
                        <RouterLink class="router-link" @click="closeMobileNav" to="proizvodi">
                            Proizvodi
                        </RouterLink>
                    </div>
                    <div 
                        class="sub-menu-opener d-flex align-items-center justify-content-center" 
                        @click="toggleCategories"
                        :class="{'bg-blue': showCategories}"    
                    >
                        <svg v-if="!showCategories" class="menu-opener-right"></svg>
                        <svg v-else class="meny-opener-down"></svg>
                    </div>
                </li>
                <!-- Start of Categories -->
                <div class="categories" :class="{'categories-open': showCategories}">
                    <ul v-for="category in categories" :key="category.id">
                        <!-- Start Of Parrent Category -->
                        <li class="d-flex justify-content-between" :class="{'light-gray': selectedCategories[category.id]}">
                            <div class="menu-item">
                                <RouterLink @click="closeMobileNav" class="router-link" :to="'proizvodi?kategorija=' + category.name">
                                    {{category.name}}
                                </RouterLink>
                            </div>
                            <div 
                                class="sub-menu-opener d-flex align-items-center justify-content-center" 
                                :class="{'bg-blue': selectedCategories[category.id]}" 
                                @click="toggleCategory(category)"
                            > 
                                <svg v-if="!selectedCategories[category.id]" class="menu-opener-right"></svg>
                                <svg v-else class="meny-opener-down"></svg>
                            </div>
                        </li>
                        <!-- End Of Parrent Category --> 
                        <!-- Start Of Child Categories v-if="selectedCategories[category.id]"  -->
                        <ul class="categories" :class="{'categories-open': selectedCategories[category.id]}">
                            <li
                                v-for="childCat in category.childCategories"
                                :key="childCat.id"
                                class="menu-item-child"
                            >
                                <RouterLink @click="closeMobileNav" class="router-link" :to="'proizvodi?kategorija=' + childCat.name">
                                    {{childCat.name}}
                                </RouterLink>
                            </li>
                        </ul>
                        <!-- End Of Child Categories -->
                    </ul>
                </div>
                <!-- End of Categories -->
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'proizvodi?akcije=true'">
                        Akcije
                    </RouterLink>
                </li>
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'o-nama'">
                        O nama
                    </RouterLink>
                </li>
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'kontakt'">
                        Kontakt
                    </RouterLink>
                </li>
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'lista-zelja'">
                        Lista zelja
                    </RouterLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import BaseBackdrop from "@/components/shared/BaseBackdrop.vue";
import { useStore } from "vuex";
import { ref, computed } from 'vue';
export default {
    components: { BaseBackdrop},
    emits: ["closed"],
    props: {
        isVisible: Boolean
    },
    setup(props, context) {
        const store = useStore();
        const showCategories = ref(false)
        const selectedCategories = ref({});

        const categories = computed(() => {
            return store.getters.getCategories;
        });

        const toggleCategories = () => {
            showCategories.value = !showCategories.value;
        }

        const closeMobileNav = () => {
            context.emit('closed');
        }

        const toggleCategory = (category) => {
            const currentValue = !!selectedCategories.value[category.id];
            selectedCategories.value[category.id] = !currentValue;
        }

        return {
            closeMobileNav,
            toggleCategories,
            toggleCategory,
            showCategories,
            categories,
            selectedCategories
        }
    },
}
</script>

<style scoped lang="scss">


.router-link {
    text-decoration: none;
    color: color(blue);
}

.light-gray {
    transition: all 0.2s ease-in;
    background: color(lightgrey);
    border: 0;
}

.bg-blue {
    transition: all 0.2s ease-in;
    background: color(blue);
    height: 54px;
}

.menu-opener-right {
  cursor: pointer;
  width: 9px;
  height: 16px;
  @include background-svg($menu-opener-right-arrow);
}

.meny-opener-down {
  cursor: pointer;
  width: 16px;
  height: 9px;
  @include background-svg($menu-closer-down-arrow);
}

.mobile-side-navbar{
    @include media(">=desktop-small"){
        display: none;
    }
}

    .backdrop{
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    .backdrop.backdrop-visible{
      opacity: 1;
      visibility: initial;
    }

    .mobile-nav {
        position: fixed;
        top: 70px;
        right: 0;
        bottom: 0;
        z-index: 100;
        overflow: hidden;
        overflow-y: auto;
        width: 340px;
        background: white;
        transform-origin: center right;
        transition: transform 0.2s;
        transform: scale(0, 1);

        @include media("<phone-medium"){
            width: 85%;
        }
    }

    .mobile-nav.slide-open-mobile-navbar{
        transform: scale(1, 1);
    }


        ul {
            padding: 0!important;
            margin: 0;
        }

        ul li {
            list-style-type: none;
            border-bottom: 1px solid color(lightgrey);
        }


        .menu-item {
            padding: 15px 8px 15px 26px;
            color: color(blue);
        }

            .sub-menu-opener {
                border-left: 1px solid color(lightgrey);
                min-width: 54px;
                min-height: 54px;
                cursor: pointer;
            }

        .categories{
            transform-origin: top center;
            transition: all 0.2s;
            overflow: hidden;
            transform: scale(1, 0);
            max-height: 0px;
        }

        .categories.categories-open{
            transform: scale(1, 1);
            max-height: 1000px;
        }

            .menu-item-child{
                padding: 15px 26px;
                color: color(darkgrey) !important;
            }
</style>