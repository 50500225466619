<template>
    <section class="d-flex flex-column">
        <h2 class="align-self-end">Galerija</h2>
        <GallerySlide/>
    </section>
</template>

<script>
import GallerySlide from './GallerySlide.vue';
export default {
    components: { GallerySlide },
    setup(){

    }
}
</script>

<style lang="scss" scoped>

h2{
    margin-right: $grid-margin-width !important;
}

.custom-slider{
    width: 214px;
    height: 7px;
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: center;
    position: relative;
}

    .custom-slider-thumb{
        width: 40px;
        height: 7px;
        background-color: color(blue);
        position: absolute;
    }

    .custom-slider-track{
        width: 100%;
        height: 3px;
        align-self: center;
        background-color: color(lightgrey);
    }

</style>