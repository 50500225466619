<template>
    <section>
        <h2>Šta su drugi rekli o nama</h2>
        <div class="first-row">
            <QuoteItem evenOdd="odd" class="quote-item" quoteText="Sve pohvale, radnici su ljubazni, i poznaju posao!!" username="Lamapfc" profession="Lokalni vodič"/>
            <QuoteItem evenOdd="even" class="quote-item middle-quote" quoteText="Vrlo dobra prodavnica vodovodne i kanalizacione opreme. Veliki izbor pločica" username="Bojan Pavlovic" profession="Lokalni vodič"/>
            <QuoteItem evenOdd="odd" class="quote-item" quoteText="Sirok izbor artikala. Ljubazno i strucno osoblje. Povoljne cene. Za svaku preporuku." username="Antonije Bogdanovic" profession="Lokalni vodič"/>
        </div>
    </section>
</template>

<script>
import QuoteItem from './QuoteItem.vue'
export default {
    components: {QuoteItem},
    setup() {
        
    },
}
</script>

<style scoped lang="scss">

section {
    padding: 0 $grid-margin-width;
    margin-right: 0px;
    @include media("<tablet", ">=phone-medium") {
      height: 725px;
    }
}

    .first-row {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;
        width: 100%;

        @include media(">=tablet" or "<phone-medium") {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .middle-quote {
        margin-right: 16px;
        margin-left: 16px;

        @include media("<desktop-small") {
            margin-right: 0;
            margin-left: 16px;
        }
    }
</style>