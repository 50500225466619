<template>
  <div v-if="product" class="price-container">
    <p class="old-price" :style="[
      product.actionPrice
        ? { 'text-decoration': 'line-through' }
        : { 'text-decoration': '', 'font-weight': 'bold'},
      ]"
    >
      {{ product.price}},00
      <span :class="{bolder: !product.actionPrice}" v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
      <span :class="{bolder: !product.actionPrice}" v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
    </p>
    <p class="action-price">
      <span v-if="product.actionPrice">
        {{ product.actionPrice}},00
        <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
        <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0 !important;

  .bolder{
    font-weight: bold;
  }
}

.price-container {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: right;
}

.action-price {
  margin: 5px 0;
}

.action-price span {
  font-size: 21px;
  color: color(red);
  font-weight: $light-font-weight;
  font-weight: bold;
}

.old-price {
  font-size: $base-font-size;
  color: color(darkgrey);
  font-weight: $light-font-weight;
}
</style>
