<template>
    <div>
        <div class="blue-wrapper d-flex flex-column">
            <h1>Politika privatnosti</h1>
            <span class="notice">
                Transparentnost sa našim korisnicima nam je važna.
                Stoga, ovde možete pronaći informacije o 
                tome kako čuvamo Vaše podatke.
            </span>
        </div>
        <div class="wrapper">
            <span>
                Vodoterm DOO brine o zaštiti Vaših ličnih podataka. Svrha ove politike privatnosti je da Vam pruži informacije u vezi prikupljanja Vaših ličnih podataka kada koristite naš sajt i povezane usluge. Davanjem svojih ličnih podataka i prihvatanjem politike privatnosti dajete saglasnost da se Vaši podaci koriste na način opisan u daljem tekstu.
                <br><br>
                Pažljivo pročitajte ovu politiku privatnosti i pratite na izmene jer se ova politika može menjati vremenom. Preporučujemo da povremeno posetite ovu stranicu. Upotrebom našeg sajta potvrđujete da ste saglasni i sa izmenama ove politike.
            </span>
            <h4>Koje personalne podatke prikupljamo i kada?</h4>
            <span>
                Personalne podatke koje prikupljamo su sledeći:<br>
                - Ime<br>
                - Prezime<br>
                - E-mail adresa<br>
                - Broj telefona<br><br>

                Ove podatke prikupljamo samo kada postavite pitanje na formi kontakt stranice.
            </span>
            <h4>Zašto prikupljamo personalne podatke?</h4>
            <span>
                Svrha prikupljanja Vaših podataka je radi dostavljanja odgovora na pitanja koje ostavite na kontakt formi. Trudimo se da ostavimo odgovor u što kraćem vremenskom periodu.
            </span>
            <h4>Da li se prikupljeni podaci dele sa trećim licima?</h4>
            <span>
                Vodoterm DOO Vaše podatke neće prosleđivati, prodavati, trgovati i iznajmljivati trećim licima. Ukoliko ne želite da prikupljamo Vaše informacije, možete nam se obratiti i mi ćemo u skladu sa tim Vaše prikupljene podatke ukloniti iz naših listi. Ukoliko bude postojala pravna obaveza, zadržavamo pravo da otkrijemo informacije o Vama ukoliko to zahteva nadležni organ.
            </span>
            <h4>Sigurnost Vaših podataka</h4>
            <span>
                Ne možemo da garantujemo bezbednost naših servera, niti garantujemo da informacije koje dostavite neće biti presretnute dok se šalju putem interneta. Praćenjem tehničkih standarda industrije radimo sve što je u našoj moći da zaštitimo Vaše podatke, kako tokom, tako i nakon primanja vaših podataka.
            </span>
        </div>
    </div>
</template>

<style scoped lang="scss">

.blue-wrapper {
    padding: 0 $grid-margin-width;
    color: color(blue);
    background-color: rgb(244, 245, 249);
}

    h1{
        margin-top: 200px !important;
    }

    .notice{
        display: inline-block;
        margin-top: 30px;
        width: 400px;
        align-self: flex-end;
        text-align: justify;
        margin-bottom: 30px;

        @include media("<phone-large"){
            width: 100%;
        }
    }

.wrapper{
    padding: 30px $grid-margin-width;
    color: color(blue);
}

    h4{
        margin-top: 30px !important;
        margin-bottom: 20px !important;
    }

    span{
        display: inline-block;
        text-align: justify;
    }

        
</style>