<template>
  <div class="d-flex flex-column align-items-center">
      <h2>Naši partneri</h2>
      <hr>
      <div class="wrapper d-flex flex-row align-items-center justify-content-between">
        <span class="circle-arrow-icon" @click="moveLeft" ref="leftArrowPartners"></span>
        <div class="carousel-wrapper">
        <Carousel
          :settings="settings"
          :breakpoints="breakpoints"
          style="cursor: pointer"
        >
          <Slide v-for="img in images" :key="img">
            <img :src="img" class="brand-icon"/>
          </Slide>
          <template #addons>
            <navigation>
              <template #next>
                <span ref="rightArrowPartners"></span>
              </template>
              <template #prev>
                <span ref="leftArrowPartners"></span>
              </template>
            </navigation>
          </template>
        </Carousel>
        </div>
        <span class="circle-arrow-icon right-arrow-icon" @click="moveRight" ref="rightArrowPartners"></span>
      </div>
      
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { ref } from "vue";
import { SETTINGS, BREAKPOINTS } from "./carouselSettingsData";
import { IMAGES } from "./partnerImages";
export default {
    components: { Carousel, Slide, Navigation},
    setup() {
        const settings = SETTINGS;
        const breakpoints = BREAKPOINTS;
        const images = IMAGES;
        const leftArrowPartners = ref(null);
        const rightArrowPartners = ref(null);

        const moveLeft = () => {
          leftArrowPartners.value.click();
        };

        const moveRight = () => {
          rightArrowPartners.value.click();
        };

        return {
          settings,
          breakpoints,
          images,
          moveLeft,
          moveRight,
          leftArrowPartners,
          rightArrowPartners,
        }
    },
}
</script>

<style lang="scss" scoped>
h2{
	margin-bottom: 30px !important;
}

hr{
	background-color: color(darkgrey);
	margin: 0;
	width: 100%;
}

.wrapper{
	width: 100vw;
	padding: 0 $grid-margin-width;
}

    .circle-arrow-icon {
      cursor: pointer;
      min-width: 40px;
      max-width: 40px;
      height: 40px;
      margin-right: 20px;
      @include icon;
      @include background-svg($left-arrow-in-circle);

      @include media("<desktop-small") {
        display: none;
      }
    }

    .right-arrow-icon {
      margin-left: 20px;
      margin-right: 0;
      transform: rotate(180deg);
    }

    .carousel-wrapper{
      height: 100px;
      padding: 20px 0;
      width: 85%;

      @include media("<desktop-small") {
        width: 100%;
      }
    }

		.carousel{
			width: 100%;
		}

		.image-wrapper{
			width: 100%;
		}

		.brand-icon{
			width: 100px;
			object-fit: contain;
		}

</style>