<template>
  <section class="hero-section">
    <img class="hero-img-super-large" src="@/assets/images/landing-page-super-large.png" alt="Kade, umivaonici, šolje, cevi, ogledala za kupatila, sve za kupatila.">
    <img class="hero-img" src="@/assets/images/landing-page.png" alt="Kade, umivaonici, šolje, cevi, ogledala za kupatila, sve za kupatila.">
    <img class="hero-img-mobile" src="@/assets/images/landing-page-mobile.png" alt="Kade, umivaonici, šolje, cevi, ogledala za kupatila, sve za kupatila.">
    <VibratingButtons/>
    <div class="title-search-container">
        <h1 class="hero-title">Mesto inspiracije<br>za vaše novo kupatilo</h1>
        <div class="d-flex call-to-action-buttons">
            <base-button @click="navigateToProductsPage()" class="base-button" buttonText="Istražite proizvode"></base-button>
            <secondary-button class="secondary-button" buttonText="Inspirišite se"></secondary-button>
        </div>
    </div>
  </section>
</template>

<script>
import SecondaryButton from '@/components/shared/SecondaryButton.vue'
import BaseButton from '@/components/shared/BaseButton.vue';
import VibratingButtons from './VibratingButtons.vue';
import { useRouter } from  'vue-router';
export default {
    components: { 
      BaseButton,
      VibratingButtons, SecondaryButton 
    },
    setup(){
      const router = useRouter();
      const navigateToProductsPage = () => {
        router.push("proizvodi");
      }

      return{
        navigateToProductsPage
      }
    }
}
</script>

<style lang="scss" scoped>

.hero-img-super-large{
  position: absolute;
  z-index: -1;
  right: 0;

  @include media("<2526px") {
    display: none;
  }
}

.hero-img{
  position: absolute;
  z-index: -1;
  right: 0;
  height: 650px;
  clip-path: inset(0 0 100% 0 );
  animation: reveal-image 1s ease-in forwards;

  @include media(">=2526px") {
    display: none;
  }

  @include media("<desktop-small") {
    height: 500px;
  }

  @include media("<tablet-large") {
    height: 350px;
  }

  @include media("<tablet") {
    height: 300px;
  }

  @include media("<phone-large") {
    display: none;
  }
    
}

.hero-img-mobile{
  position: absolute;
  z-index: -1;
  height: 400px;
  width: 100%;
  left: 50%;
  top: 218px;
  transform: translate(-50%, 0);
  object-fit: cover;

  @include media(">=phone-large") {
    display: none;
  }

  @include media("<phone-medium") {
    top: 240px;
    height: 320px;
  }

   @include media("<phone-small") {
    top: 270px;
    height: 250px;
  }
}

  @keyframes reveal-image {
    100% {
      clip-path: inset(0 0 0 0);
    }
  }

.title-search-container{
    margin: 180px 0 149px $grid-margin-width;

    @include media("<desktop-small") {
      margin-top: 120px;
      margin-bottom: 125px;
    }

    @include media("<tablet-large") {
      margin-top: 47px;
      margin-bottom: 90px;
    }

    @include media("<tablet") {
      margin-top: 40px;
      margin-bottom: 80px;
    }

    @include media("<phone-large") {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: $grid-margin-width;
      margin-top: 30px;
      margin-bottom: 274px;
    }

    @include media("<phone-medium") {
      margin-bottom: 244px;
    }

    @include media("<phone-small") {
      margin-bottom: 224px;
    }
}

    .hero-title{
        
        margin-bottom: 30px !important;
        
        @include media("<desktop-small") {
          margin-bottom: 23px !important;
        }

        @include media("<tablet-large") {
          margin-bottom: 14px !important;
        }

        @include media("<phone-large") {
          text-align: center;
        }

    }

    .call-to-action-buttons{
        @include media("<phone-large") {
          flex-direction: column;
          width: 50vw;
        }
    }

      .base-button{
          width: 189px;
          margin-right: 20px;

          @include media("<tablet") {
            width: 145px;
          }

          @include media("<phone-large") {
            width: 100%;
            margin: 0 0 15px 0;
          }
      }

      .secondary-button{
        visibility: hidden;
        width: 189px;

        @include media("<tablet") {
          width: 145px;
        }

        @include media("<phone-large") {
          width: 100%;  
        }
      }
</style>


