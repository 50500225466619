<template>
    <div>
        <div class="d-flex contact-page-wrapper">
            <div class="contact-info">
                <h2>Kontakt info</h2>
                <div class="blocks-wrapper d-flex flex-column">
                    <div class="phone-email-wrapper d-flex flex-column">
                        <section class="email contact-block-wrapper">
                            <div class="d-flex">
                                <svg class="email-icon"></svg>
                                <div class="d-flex flex-column">
                                    <span class="fw-bold mb-1">E-mail adresa</span>
                                    <span>vodoterm.info@gmail.com</span>
                                </div>
                            </div>
                        </section>
                        <section class="phone-numbers contact-block-wrapper">
                            <div class="d-flex">
                                <svg class="phone-icon"></svg>
                                <div class="d-flex flex-column">
                                    <span class="fw-bold mb-1">Kontakt telefoni</span>
                                    <span class="mb-1">034 345580</span>
                                    <span class="mb-1">034 300530</span>
                                    <span>060 3019010</span>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="d-flex flex-column">
                        <section class="location contact-block-wrapper">
                            <div class="d-flex">
                                <svg class="location-icon"></svg>
                                <div class="d-flex flex-column">
                                    <span class="fw-bold mb-1">Lokacija</span>
                                    <span class="mb-1">70 Vladimira Rolovića, 34000, Kragujevac</span>
                                    <span>Šumadijski okrug, Srbija</span>
                                </div>
                            </div>
                        </section>
                        <section class="work-hours contact-block-wrapper">
                            <div class="d-flex">
                                <svg class="work-hours-icon"></svg>
                                <div class="d-flex flex-column">
                                    <span class="fw-bold title">Radno vreme</span>
                                    <div class="d-flex flex-row">
                                        <div class="d-flex flex-column days">
                                            <span class="mb-1">pon - pet:</span>
                                            <span class="mb-1">sub:</span>
                                            <span>ned:</span>
                                        </div>
                                        <div class="d-flex flex-column time">
                                            <span class="mb-1">07:00-19:00</span>
                                            <span class="mb-1">07:00–15:00</span>
                                            <span>Zatvoreno</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div class="vl"></div>
            <div class="question-form">
                <h2>Imate neko pitanje?</h2>
                <form @submit.prevent="sendQuestion" class="w-100">
                    <div class="d-flex flex-column w-100">
                        <div class="d-flex flex-row group-fields">
                            <div class="form-group me-2" :class="{'error-div': errors['firstName'][0]}">
                                <label>Ime</label>
                                <input @input="checkInput('firstName')" @blur="checkInput('firstName')" v-model="firstName" class="form-control" type="text">
                                <span class="error-text shift" :class="{'error-text-visible': errors['firstName'][0]}">Morate uneti validno ime</span>
                            </div>
                            <div class="form-group ms-2" :class="{'error-div': errors['lastName'][0]}">
                                <label>Prezime</label>
                                <input @input="checkInput('lastName')" @blur="checkInput('lastName')" v-model="lastName" class="form-control" type="text">
                                <span class="error-text shift" :class="{'error-text-visible': errors['lastName'][0]}">Morate uneti validno prezime</span>
                            </div>
                        </div>
                        <div class="d-flex flex-row group-fields">
                            <div class="form-group me-2" :class="{'error-div': errors['email'][0]}">
                                <label>E-mail adresa</label>
                                <input @input="checkInput('email')" @blur="checkInput('email')" v-model="email" class="form-control" :class="{'error-input': errors['email'][0]}" type="text">
                                <span class="error-text shift" :class="{'error-text-visible': errors['email'][0]}">Morate uneti validan email</span>
                            </div>
                            <div class="form-group ms-2" :class="{'error-div': errors['phoneNumber'][0]}">
                                <label>Broj telefona</label>
                                <input @input="checkInput('phoneNumber')" @blur="checkInput('phoneNumber')" v-model="phoneNumber" class="form-control" type="text">
                                <span class="error-text shift" :class="{'error-text-visible': errors['phoneNumber'][0]}">Morate uneti validan broj telefona</span>
                            </div>
                        </div>
                        <div 
                            class="form-group me-2 d-flex flex-column" 
                            :class="{'error-div': errors['message'][0]}"
                        >
                            <label>Poruka</label>
                            <textarea @input="checkInput('message')" @blur="checkInput('message')" v-model="message" rows="6" cols="50"></textarea>
                            <span class="error-text" :class="{'error-text-visible': errors['message'][0]}">Poruka ne može biti prazna</span>
                        </div>
                        <BaseButton :disabled="isThereInputErrors" type="submit" class="button align-self-end" buttonText="Pošalji poruku"></BaseButton>
                    </div>
                </form>
            </div>
        </div>
        <div 
            tabindex="0"
            id="map-container"
            @mouseenter="onMouseEnterMap"
            @mousedown="onMouseDown"
            @mouseleave="onMouseLeaveMap"
            @keydown.shift="onKeyDown($event)"
            @keyup="shiftPressed=false" 
            class="map d-flex justify-content-center"
        >
            <div 
                class="scroll-message-map d-flex flex-row align-items-center justify-content-center" 
                :class="{'scroll-message-map-active': mapScrollMessageDisplayed, 'scroll-message-map-display-none': mapScrollMessageDisplayed}"
            >
                <span class="scroll-message-text">Koristite shift + skrol kako biste zumirali na mapi</span>
            </div>
            <MapContainer :shiftPressed="shiftPressed"></MapContainer>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, watch, inject } from "vue";
import axios from 'axios';
import MapContainer from './MapContainer.vue';
import BaseButton from '../../components/shared/BaseButton.vue';
import validate from '@/util/validators.js';

export default {
    components: { BaseButton, MapContainer },
    setup() {
        const toast = inject('toast');
        const firstName = ref('');
        const lastName = ref('');
        const email = ref('');
        const phoneNumber = ref('');
        const message = ref('');
        const shiftPressed = ref(false);
        const mapFocused = ref(false);
        const mapScrollMessageDisplayed = ref(false);
        const isThereInputErrors = ref(true);
        const inputs = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: ''
        });
        const errors = ref({
            firstName: [false, {type: 'MIN_LENGTH', num: 2}],
            lastName: [false, {type: 'MIN_LENGTH', num: 2}],
            phoneNumber: [false, {type: 'NUMBER'}],
            email: [false, {type: 'EMAIL'}],
            message: [false, {type: 'MIN_LENGTH', num: 5}]
        });

        const sendQuestion = () => {
            if(isThereInputErrors.value) {
                // display message
                return;
            }
            const {firstName, lastName, email, phoneNumber, message} = inputs;
            const body = {
                firstName: firstName, 
                lastName: lastName,
                email: email, 
                phoneNumber: phoneNumber, 
                message: message
            };
            axios.post(`${process.env.VUE_APP_API_URL}/api/Contact`, body)
            .then(() => {
                toast.success("Poruka je poslata");
            })
            .catch(() => {
                // handle errors
                toast.error("Poruka nije poslata");
            })
        }

        const onMouseDown = () => {
            mapScrollMessageDisplayed.value = false;
        }

        const onKeyDown = (event) => {
            if(event.shiftKey){
                shiftPressed.value = true;
                mapScrollMessageDisplayed.value = false;
            }
        }

        const onMouseEnterMap = () => {
            document.getElementById('map-container').focus({ preventScroll: true });
            mapFocused.value = true;
        }

        const onMouseLeaveMap = () => {
            document.getElementById('map-container').blur();
            mapFocused.value = false;
        }

        onMounted(() => {
            document.onscroll = function(){
                if(mapFocused.value && !mapScrollMessageDisplayed.value){
                    mapScrollMessageDisplayed.value = true;
                    setTimeout(
                        function () { 
                            mapScrollMessageDisplayed.value = false;
                        },
                        2000
                    )
                }
            };
        })

        const checkInput = (val) => {
            errors.value[val][0] = !validate(inputs[val], errors.value[val][1]);
        }

        watch(() => errors.value, () => {
            for(let key in errors.value) {
                if(errors.value[key][0]) {
                    isThereInputErrors.value = true;
                    return;
                }
            }
            isThereInputErrors.value = false;
        }, { deep: true });

        return {
            firstName,
            lastName,
            email,
            phoneNumber,
            message,
            shiftPressed,
            sendQuestion,
            onMouseDown,
            onKeyDown,
            onMouseEnterMap,
            onMouseLeaveMap,
            mapScrollMessageDisplayed,
            ...toRefs(inputs),
            errors,
            checkInput,
            isThereInputErrors
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_contact.scss';

.contact-page-wrapper{
    padding: 0 $grid-margin-width;
    
    @include media("<tablet-large"){
        flex-direction: column;
    }
}

    .vl {
        border-right: 1px solid color(lightgrey);

        @include media("<tablet-large"){
            display: none;
        }
    }

    .contact-info{
        width: 36.42%;
        padding: 30px 4.71% 30px 0;
        color: color(blue);

        @include media("<tablet-large"){
            order: 1;
            padding: 0;
            width: 100%;
        }
    }

        .blocks-wrapper{
            @include media("<tablet-large"){
                flex-direction: row !important;
                justify-content: space-between;
            }

            @include media("<phone-large"){
                flex-direction: column !important;
            }

        }

            .phone-email-wrapper{
                @include media("<tablet-large", ">=phone-large"){
                    width: 50%;
                    margin-right: 15px;
                }

            }

                .contact-block-wrapper{
                    margin-bottom: 20px;
                }

                    h2{
                        margin-bottom: 20px !important;
                    }

                    span{
                        display: inline-block;
                    }

                    .email{
                        @include media("<tablet-large"){
                            order: 1;
                        }
                    }

                        .email-icon{
                            width: 24px;
                            min-width: 24px;
                            height: 24px;
                            margin-right: 15px;
                            @include icon;
                            @include background-svg($email-icon);
                        }

                    .phone-icon{
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        margin-right: 15px;
                        @include icon;
                        @include background-svg($phone-icon);
                    }

            .location-work-hours-wrapper{
                @include media("<tablet-large", ">=phone-large"){
                    width: 50%;
                }
            }

                .location{
                    @include media("<tablet-large", ">=phone-large"){
                        height: 108px;
                    }
                }

                    .location-icon{
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        margin-right: 15px;
                        @include icon;
                        @include background-svg($location-icon);
                    }

                        .work-hours-icon{
                            width: 24px;
                            min-width: 24px;
                            height: 24px;
                            margin-right: 15px;
                            @include icon;
                            @include background-svg($work-hours-icon);
                        }

                        .days{
                            margin-right: 20px;
                        }

    .question-form{
        width: 63.58%;
        padding: 30px 0 30px 4.71%;

        @include media("<tablet-large"){
            width: 100%;
            padding: 30px 0 0 0;
        }
    }

        .group-fields{

            @include media("<phone-medium"){
                flex-direction: column !important;
            }
            
        }

        .form-group{
            width: 100%;
            @include media("<phone-medium"){
                margin: 0 !important;
            }
        }

            .error-div {
                label {
                    color: red;
                }

                input, textarea {
                    border-color: red !important;
                }
            }

                .error-text{
                    color: red;
                    font-size: 12px;
                    visibility: hidden;
                }

                .error-text.error-text-visible{
                    visibility: initial;
                }

                .shift{
                    top: -4px;
                    position: relative;
                }

        .button{
            width: 25vw;
            margin-top: 30px;

            @include media("<tablet-large"){
                width: 100%;
                margin-bottom: 30px;
            }
        }

    .map{
        height: 450px;
        width: 100%;
        position: relative;

        @include media("<tablet-large"){
            padding: 20px $grid-margin-width 30px $grid-margin-width ;
        }

        &:focus{
            outline: none;
        }
    }

        .scroll-message-map{
            position: absolute;
            height: 450px;
            width: 100%;
            z-index: 99;
            background: rgba(0, 0, 0, 0.7);
            transition: all 0.2s ease-in;
            padding: 0 $grid-margin-width 0 $grid-margin-width ;
            opacity: 0;
            visibility: hidden;

            @include media("<tablet-large"){
                height: 400px;
                width: calc(100% - #{$grid-margin-width}*2);
            }
        }

        .scroll-message-map.scroll-message-map-active{
            visibility: initial;
            opacity: 1;
        }

        .scroll-message-map.scroll-message-map-display-none{
            display: none;
        }

            .scroll-message-text{
                color: white;
                font-size: $h4-size;
            }

</style>