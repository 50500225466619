<template>
    <div class="full-size-image-container" :class="{'full-size-image-container-open': isImageViewerOpen}">
        <BaseBackdrop :leftPosition='0' @clicked="closeImageViewer"/>
        <svg class="circle-arrow-icon" @click="showPrevImage"></svg>
        <figure id="figure" class="zoom" @mousemove="zoom($event)" :style="{'background-image': 'url(' + openImagePath + ')', 'background-size': $refs.img ? $refs.img.width*1.5 + 'px ' + $refs.img.height*1.5 + 'px' : '0px 0px'}">
            <img ref="img" @mousedown="toggleZoom()" :src="openImagePath" class="full-size-image" :class="{'full-size-image-zoomed' : isZoomEnabled}" alt="Gallery photo">
        </figure>
        <svg class="circle-arrow-icon right-arrow-icon" @click="showNextImage"></svg>
    </div>
</template>

<script>
import BaseBackdrop from './BaseBackdrop.vue';
import { ref } from 'vue';
import isMobileDevice from '@/util/mobile-detector.js';
export default {
    components: { BaseBackdrop },
    props: {
        images: Array
    },
    setup(props){
        const isZoomEnabled = ref(false);
        const openImagePath = ref("");
        const isImageViewerOpen = ref(false);

        window.addEventListener('popstate', function(event) {
            if (isImageViewerOpen.value) {
                event.preventDefault();
                closeImageViewer();
            }
        });

        const showNextImage = () => {
            var index = props.images.findIndex(img => img === openImagePath.value);
            if(index != props.images.length-1){
                isZoomEnabled.value = false;
                openImagePath.value = props.images[index + 1];
            }
        }

        const showPrevImage = () => {
            var index = props.images.findIndex(img => img === openImagePath.value);
            if(index != 0){
                isZoomEnabled.value = false;
                openImagePath.value = props.images[index - 1];
            }
        }

        const toggleZoom = () => {
            if(isMobileDevice() == false)
                isZoomEnabled.value = !isZoomEnabled.value;
        }

        const zoom = (e) => {
            if(isZoomEnabled.value){
                var zoomer = e.currentTarget;
                zoomer.style.backgroundSize = e.offsetWidth*2 + 'px ' + e.offsetHeight*2 + 'px ';
                //zoomer.style.backgroundSize = e.offsetWidth*2 + 'px ' + e.offsetHeight*2 + 'px ';
                let offsetX;
                let offsetY;
                e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
                e.offsetY ? offsetY = e.offsetY : offsetY = e.touches[0].pageY
                // console.log('offsetX ' + offsetX)
                // console.log('offsetY ' + offsetY)
                // console.log('offsetWidth ' + zoomer.offsetWidth)
                // console.log('offsetHeight' + zoomer.offsetHeight)
                var x = offsetX/zoomer.offsetWidth*100
                var y = offsetY/zoomer.offsetHeight*100
                zoomer.style.backgroundPosition = x + '% ' + y + '%';
            }
        }

        const openImageViewer = (img) => {
            openImagePath.value = img;
            document.documentElement.style.overflow = 'hidden';
            isImageViewerOpen.value = true;
        }

        const closeImageViewer = () => {
            document.documentElement.style.overflow = 'auto';
            isZoomEnabled.value = false;
            isImageViewerOpen.value = false;
        }

        return {
            isImageViewerOpen,
            openImagePath,
            isZoomEnabled,
            showNextImage,
            showPrevImage,
            toggleZoom,
            zoom,
            closeImageViewer,
            openImageViewer
        }
    }
}
</script>

<style lang="scss" scoped>

.full-size-image-container{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in;
    cursor: initial;

    @include media("<tablet") {
        justify-content: center;
    }
}

.full-size-image-container-open{
    opacity: 1;
    visibility: initial;
}

    .full-size-image{
        z-index: 1001;
        max-height: 90vh;
        max-width: 70vw;
        object-fit: contain;
        cursor: zoom-in;
        transition: opacity 0.5s;
    }

    .full-size-image.full-size-image-zoomed{
        opacity: 0;
        cursor: zoom-out;
    }

    .circle-arrow-icon {
        cursor: pointer;
        z-index: 1001;
        width: 40px;
        height: 40px;
        margin-left: $grid-margin-width;
        @include noselect;
        @include background-svg($left-arrow-in-circle);

        @include media("<tablet") {
            display: none;
        }
    }

    .right-arrow-icon {
        transform: rotate(180deg);
        margin-left: 0;
        margin-right: $grid-margin-width;
    }

    .zoom {
        position: relative;
        z-index: 1001;
        overflow: hidden;
        cursor: zoom-in;

        @include media("<tablet") {
            max-height: 80vh;
            max-width: calc(100% - 2 * #{$grid-margin-width});
        }
    }

</style>