const lazyLoad = (view) => {
  return () => import(`../pages/admin/${view}`);
}

const routes = 
{
    path: "/admin",
    component: lazyLoad('AdminPage'),
    children: [
      {
        path: "login",
        name: "Login",
        component: lazyLoad('LoginPage'),
      },
      {
        path: "zaboravili-ste-lozinku",
        name: "ForgotPassword",
        component: lazyLoad('ForgotPasswordPage')
      },
      {
        path: "resetuj-lozinku",
        name: "ResetPasswordPage",
        component: lazyLoad('ResetPasswordPage')
      },
      {
        path: "dashboard",
        component: lazyLoad('AdminModule'),
        children: [
          {
            path: "",
            name: "Dashboard",
            component: lazyLoad('AdminDashboard'),
          },
          {
            path: "edit/:id",
            name: "Edit",
            component: lazyLoad('Edit'),
          },
          {
            path: "logs",
            name: "Logs", 
            component: lazyLoad('SerilogLogs'),
          }
        ]
      }
    ],
};

export default routes;