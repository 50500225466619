<template>
  <div
    class="backdrop"
    @click="onClick"
    @mouseover="onHover"
    :style="[
      !!topPosition ? { top: topPosition + 'px' } : { top: '0px' },
      !!zindex ? {'z-index': zindex}: {'z-index': 99}
    ]"
  >
  <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    topPosition: String,
    zindex: String
  },
  emits: ["clicked", "hovered"],
  setup(props, context) {
    const onClick = () => {
      context.emit("clicked");
    };

    const onHover = () => {
      context.emit("hovered");
    };

    return {
      onClick,
      onHover,
    };
  },
};
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
}
</style>
