<template>
  <div class="add-to-wish-list-container">
    <div class="add-to-wish-list-count">
      <button class="btn" @click="removeOne">-</button>
      <button class="btn">{{ count }}</button>
      <button class="btn" @click="addOne">+</button>
    </div>
    <button class="btn add-to-wish-list-btn w-100" @click="addToWishlist">
      Sačuvaj
    </button>
  </div>
</template>

<script>
import { onMounted, ref, inject } from "vue";
export default {
  props: {
    product: Object,
  },
  emits: ["wishlistChanged"],
  setup(props, context) {

    const count = ref(1);
    const toast = inject('toast');

    onMounted(() => {
      const wishList = getWishList();
      if (wishList && wishList[props.product.id]) {
        count.value = wishList[props.product.id].count;
      }
    });

    const getWishList = () => {
      let parsedData = {};
      try {
        parsedData = JSON.parse(localStorage.getItem("vodotermWishList"));
      } catch (error) {
        // handle error
      }
      return parsedData;
    };

    const addOne = () => {
      count.value = count.value + 1;
    };

    const removeOne = () => {
      count.value = count.value > 1 ? count.value - 1 : 1;
    };

    const addToWishlist = () => {
      let wishList = getWishList();
      if (!wishList) wishList = {};
      if (!wishList[props.product.id]) {
        wishList[props.product.id] = {};
      }

      wishList[props.product.id].count = count.value;
      wishList[props.product.id].name = props.product.name,
      wishList[props.product.id].price = props.product.actionPrice ? props.product.actionPrice : props.product.price;
      wishList[props.product.id].categoryName = props.product.categoryName;
      wishList[props.product.id].imageUrl = process.env.VUE_APP_API_URL + "/" + props.product.imageUrl;

      if(props.product.categoryName.toLowerCase().includes('pločice')){
        wishList[props.product.id].squaresInBox = props.product.characteristics['Kvadrata u kutiji'];
      }

      localStorage.setItem("vodotermWishList", JSON.stringify(wishList));
      context.emit('wishlistChanged');
      toast.success("Proizvod je dodat u listu želja");
    };

    return {
      count,
      addOne,
      removeOne,
      addToWishlist,
    };
  },
};
</script>

<style scoped lang="scss">
.add-to-wish-list-container {
  display: flex;
}

.add-to-wish-list-btn {
  background: color(blue);
  color: white;
  font-weight: $bold-font-weight;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-left: 20px;
  width: 100%;
  display: block;
}

.add-to-wish-list-btn:hover {
  background: color(blue);
  color: white;
  font-weight: $bold-font-weight;
  border-radius: 0 !important;
}

.add-to-wish-list-count {
  display: flex;
}

.add-to-wish-list-count button {
  border: 1px solid color(lightgrey) !important;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  color: color(blue) !important;
  width: 40px;
  height: 40px;
  font-weight: bold;
}
</style>
