<template>
  <div class="contact">
    <h4>Kontakt telefon</h4>
    <div class="footer-info-container">
      <span>034 356218</span>
    </div>
    <div class="footer-info-container">
      <span>034 356218</span>
    </div>
    <div class="footer-info-container">
      <span>034 356218</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-info-container {
  margin-bottom: 0px;
  margin-top: 8px;
  font-size: 14px;
}

h4, .footer-info-container {
  color: white;
}

.contact {
  @include media("<tablet") {
    margin-top: 32px;
  }
}


</style>
