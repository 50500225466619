<template>
    <section class="wishlist-section d-flex flex-column">
        <h4>Lista želja</h4>
        <div class="wishlist-wrapper d-flex">
            <div class="wislist-products">
                <template v-for="product in wishlistProducts" :key="product.id">
                    <div class="wishlist-product d-flex justify-content-between" 
                        :class="{'remove-element': product.id == isRemovingFromWishList}"
                    >
                        <div class="d-flex flex-row w-100">
                            <div class="d-flex align-items-center">
                                <img class="pointer" :src="product.imageUrl" alt="img" @click="navigateToProductDetailsPage(product.id)">
                            </div>
                            <div class="d-flex flex-row justify-content-between w-100">
                                <div class="d-flex flex-column w-100">
                                    <div class="product-name-and-remove-icon d-flex justify-content-between align-items-center w-100">
                                        <span class="wishlist-product-name pointer" @click="navigateToProductDetailsPage(product.id)">{{product.name}}</span>
                                        <svg class="remove-icon" @click.stop="removeFromWishList(product.id)"></svg>
                                    </div>
                                    <div class="product-price-and-quantity-wrapper d-flex justify-content-between align-items-center w-100 mt-2">
                                        <span class="wishlist-product-price">{{product.price}},00
                                            <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
                                            <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
                                            <span v-if="product.categoryName.toLowerCase().includes('pločice') && product.squaresInBox">, Cena kutije: ~{{Math.ceil(parseFloat(product.squaresInBox) * product.price)}},00 RSD</span>
                                        </span>
                                        <div class="quantity d-flex justify-content-center align-items-center">
                                            <span v-if="!product.categoryName.toLowerCase().includes('pločice')" class="quantity-text mobile">Komada</span>
                                            <span v-if="product.categoryName.toLowerCase().includes('pločice') && product.squaresInBox" class="quantity-text mobile">Kutija</span>
                                            <span v-if="product.categoryName.toLowerCase().includes('pločice') && !product.squaresInBox" class="quantity-text mobile">m&#178;</span>
                                            <div @click.stop="toggleCountDropdown(product.id)" class="select-div d-flex justify-content-center align-items-center">
                                                <span>{{product.count}}</span>
                                                <svg class="select-arrow"></svg>
                                                <div v-if="product.id == displayedOption" v-click-away="removeOption" class="product-count-dropdown d-flex flex-column">
                                                    <div class="select-div-option" @click.stop="addItem(product.id)">+</div>
                                                    <div class="select-div-option" @click.stop="removeItem(product.id, product.count)">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="wishlist-remove" @click.stop="removeFromWishList(product.id)">Ukloni</span>
                                </div>
                                <div class="d-flex flex-column">
                                    <span v-if="!product.categoryName.toLowerCase().includes('pločice')" class="quantity-text desktop">Komada</span>
                                    <span v-if="product.categoryName.toLowerCase().includes('pločice') && product.squaresInBox" class="quantity-text desktop">Kutija</span>
                                    <span v-if="product.categoryName.toLowerCase().includes('pločice') && !product.squaresInBox" class="quantity-text desktop">m&#178;</span>
                                    <div class="d-flex">
                                        <div class="square" style="cursor: pointer;" @click.stop="removeItem(product.id, product.count)">-</div>
                                        <div class="square">{{product.count}}</div>
                                        <div class="square" style="cursor: pointer;" @click.stop="addItem(product.id)">+</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="wishlistProducts.length > 0" class="wishlist-details">
                <p class="wishlist-details-title">Detalji</p>
                <template v-for="product in wishlistProducts" :key="product.id">
                    <div class="wishlist-detail">
                        <p>{{product.count}} x {{product.name}}</p>
                    </div>
                </template>
                <div class="wishlist-details-total d-flex justify-content-between">
                    <span>Ukupno</span>
                    <span>{{totalPrice}},00 RSD</span>
                </div>
            </div>
        </div>
        <div v-if="wishlistProducts.length == 0" class="d-flex no-content justify-content-center w-100">
            Trenutno nemate sačuvanih proizvoda.
        </div>
    </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const wishlistProducts = ref([]);
        const totalPrice = ref(0);
        const displayedOption = ref(-1);
        const isRemovingFromWishList = ref(-1);

        onMounted(() => {
            getWishlistProducts();
        })

        const getWishlistProducts = () => {
            const storageProducts = getFromLocalStorage();
            if(storageProducts) {
                const arr = [];
                let sum = 0;
                for(const item in storageProducts) {
                    const prod = storageProducts[item];
                    arr.push({...prod, id: item});
                    if(prod.categoryName.toLowerCase().includes('pločice') && prod.squaresInBox){
                       sum += Math.ceil(parseFloat(prod.squaresInBox) * prod.price * prod.count); 
                    } else {
                        sum += prod.price * prod.count;
                    }
                }

                totalPrice.value = sum;
                arr.sort((a,b) => (a.count > b.count) ? 1 : -1);
                wishlistProducts.value = arr;
            }
        }

        const getFromLocalStorage = () => {
            const storageData = localStorage.getItem('vodotermWishList');
            let parsedData = null;
            try {
                const obj = JSON.parse(storageData);
                parsedData = obj;
            } catch (err) {
                // handle error
            }
            return parsedData;
        }

        const setToLocalStorage = (obj) => {
            localStorage.removeItem('vodotermWishList');
            localStorage.setItem('vodotermWishList', JSON.stringify(obj));
        }

        const navigateToProductDetailsPage = (productId) => {
            router.push(`/proizvodi/${productId}`);
        }

        const removeFromWishList = (id) => {
            isRemovingFromWishList.value = id;
            setTimeout(() => {
                const storageProducts = getFromLocalStorage();
                let subtractedPrice = 0;
                if(storageProducts[id].categoryName.toLowerCase().includes('pločice') && storageProducts[id].squaresInBox){
                    subtractedPrice = Math.ceil(parseFloat(storageProducts[id].squaresInBox) * storageProducts[id].price * storageProducts[id].count)
                } else {
                    subtractedPrice = storageProducts[id].count * storageProducts[id].price;
                }
                delete storageProducts[id];
                setToLocalStorage(storageProducts);
                wishlistProducts.value = wishlistProducts.value.filter(p => p.id !== id);
                
                totalPrice.value = totalPrice.value - subtractedPrice;
                store.dispatch("setWishlistCount");
            }, 300)
        }

        const addItem = (id) => {
            const storageProducts = getFromLocalStorage();
            storageProducts[id].count++;
            if(storageProducts[id].categoryName.toLowerCase().includes('pločice') && storageProducts[id].squaresInBox){
                totalPrice.value = totalPrice.value + Math.ceil(parseFloat(storageProducts[id].squaresInBox) * storageProducts[id].price)
            } else {
                totalPrice.value = totalPrice.value + storageProducts[id].price;
            }
            
            setToLocalStorage(storageProducts);
            const prod = wishlistProducts.value.find(p => p.id === id);
            prod.count++;
        }

        const removeItem = (id, count) => {
            if(count === 1) {
                removeFromWishList(id);
                return;
            }

            const storageProducts = getFromLocalStorage();
            storageProducts[id].count--;
            if(storageProducts[id].categoryName.toLowerCase().includes('pločice') && storageProducts[id].squaresInBox){
                totalPrice.value = totalPrice.value - Math.ceil(parseFloat(storageProducts[id].squaresInBox) * storageProducts[id].price)
            } else {
                totalPrice.value = totalPrice.value - storageProducts[id].price;
            }
            setToLocalStorage(storageProducts);
            const prod = wishlistProducts.value.find(p => p.id === id);
            prod.count--;
        }

        const toggleCountDropdown = (id) => {
            displayedOption.value = id;
        }

        const removeOption = () => {
                displayedOption.value = -1;
        }

        return {
            wishlistProducts,
            totalPrice,
            displayedOption,
            navigateToProductDetailsPage,
            removeFromWishList,
            isRemovingFromWishList,
            addItem,
            removeItem,
            toggleCountDropdown,
            removeOption
        }
    },
}
</script>

<style scoped lang="scss">
.pointer {
    cursor: pointer;
}

.wishlist-section {
    padding: 0 $grid-margin-width;
    min-height: 500px;
}

    h4 {
        margin-top: 30px !important;
        margin-bottom: 20px !important; 

        @include media("<phone-large"){
            font-size: $base-font-size !important;
        }
    }

    .wishlist-wrapper {
        width: 100%;

        @include media("<tablet-large"){
            flex-direction: column;
        }
    }

        .wislist-products {
            width: 100%;
            margin-right: 30px;
            @include media("<tablet-large"){
                margin: 0;
            }
        }

            .wishlist-product {
                padding: 20px;
                margin-bottom: 30px;
                transition: all 0.2s ease-out;
                @include shadow;

                @include media("<=phone-large"){
                    padding: 15px 0 20px 0;
                    box-shadow: none;
                    border-bottom: 1px solid color(lightgrey);
                    margin-bottom: 15px;
                }
            }

            .remove-element {
                transform: scale(0,1) !important;
                transform-origin: left;
            }

                img {
                    height: 100px;
                    width: 100px;
                    margin-right: 20px;

                    @include media("<=phone-large"){
                        height: 77px;
                        width: 77px;
                    }

                    @include media("<phone-small") {
                        margin-right: 10px;
                        align-self: flex-start !important;
                    }
                }

                .product-name-and-remove-icon{
                    margin-top: 7px;
                    margin-bottom: 10px;
                }

                    .wishlist-product-name {
                        display: inline-block;
                        color: color(blue);
                        font-weight: bold;
                        font-size: $base-font-size;
                        @include media("<phone-small") {
                            margin-top: 0;
                        }
                    }

                    .remove-icon {
                        width: 10px;
                        height: 10px;
                        @include background-svg($wishlist-remove-icon);
                        cursor: pointer;

                        @include media(">phone-large"){
                            display: none;
                        }
                    }

                .product-price-and-quantity-wrapper{
                    @include media("<phone-medium"){
                        flex-direction: column !important;
                        align-items: flex-start !important;
                    }
                }

                    .wishlist-product-price {
                        color: color(darkgrey);
                        display: inline-block;
                        margin-bottom: 10px;
                        @include media("<=phone-large"){
                            margin: 0;
                            align-self: flex-start !important; 
                        }
                    }

                    .quantity{
                        @include media("<phone-medium"){
                            margin-top: 10px;
                        }

                        @include media("<250px"){
                            flex-direction: column !important;
                            align-items: flex-start !important;
                        }
                    }

                        .quantity-text{
                            color: color(darkgrey);
                        }

                        .quantity-text.mobile{
                            @include media(">phone-large"){
                                display: none;
                            }
                        }

                        .quantity-text.desktop{
                            margin-top: 17px;
                            margin-bottom: 10px;
                            @include media("<=phone-large"){
                                display: none;
                            }
                        }

                .wishlist-remove {
                    color: color(red);
                    cursor: pointer;
                    
                    @include media("<=phone-large"){
                        display: none;
                    }
                }

            .select-div {
                position: relative;
                @include media(">phone-large"){
                    display: none !important;
                }

                @include media("<=phone-large") {
                    width: 54px;
                    height: 25px;
                    border: 1px solid color(darkgray);
                    margin-left: 10px;

                    span {
                        display: inline-block;
                        color: color(blue);
                        font-size: $base-font-size;
                        font-weight: bold;
                    }

                    .select-arrow {
                        width: 24px;
                        height: 24px;
                        @include icon;
                        @include background-svg($wishlist-mobile-select-arrow);
                        cursor: pointer;
                    }
                }

                @include media("<250px"){
                    margin-left: 0;
                    margin-top: 5px;
                }

            }

                .product-count-dropdown{
                    position: absolute;
                    top: 24px;
                    @include media(">phone-large"){
                        display: none !important;
                    }
                }

                    .select-div-option {
                        width: 54px;
                        height: 25px;
                        border: 1px solid color(darkgray);
                        border-top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: color(blue);
                        font-weight: bold;
                        background: white;
                        cursor: pointer;
                        z-index: 5;

                        @include media(">=tablet"){
                            display: none!important;
                        }
                    }

            .square {
                width: 40px;
                height: 40px;
                display: flex;
                align-items:  center;
                justify-content: center;
                color: color(blue);
                font-weight: bold;
                border: 1px solid color(lightgrey);

                @include noselect;

                @include media("<=phone-large"){
                    display: none;
                }
            }
        
        .wishlist-details {
            padding: 20px 30px 20px 20px;
            @include shadow;
            width: 100%;
            margin-bottom: auto;
            @include media(">=tablet-large"){
                width: 315px;
            }

            @include media("<tablet-large"){
                margin-bottom: 30px !important;
            }

            @include media("<=phone-large"){
                box-shadow: none;
                padding-bottom: 0;
            }
        }

            .wishlist-details-title {
                color: color(blue);
                font-weight: bold;
                font-size: $base-font-size;
                margin-bottom: 20px;
            }

            .wishlist-detail {
                border-bottom: 1px solid color(lightgrey);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

                .wishlist-detail p {
                    color: color(darkgrey);
                    margin-bottom: 10px;
                }

            .wishlist-details-total {
                margin-top: 20px;
            }

                .wishlist-details-total  span {
                    color: color(blue);
                    font-size: $base-font-size;
                    font-weight: bold;;
                }

    .no-content{
        margin-top: auto;
        margin-bottom: auto;
    }
</style>