import { createStore } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const store = createStore({
  state() {
    return {
      actionProducts: [],
      categories: [],
      //products of certain category (not all products)
      products: [],
      wishlistCount: 0
    };
  },

  mutations: mutations,

  actions: actions,

  getters: getters,
});

export default store;
