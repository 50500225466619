<template>
  <div v-if="isLoading" class="vw-100 vh-100 d-flex align-items-center justify-content-center">
    <LoadingSpinner />
  </div>
  <router-view v-else />
</template>

<script>
import LoadingSpinner from './components/shared/LoadingSpinner.vue'
import { ref, watchEffect } from 'vue';
import { useStore } from "vuex";
export default {
  components: {LoadingSpinner},
  setup() {
    const isLoading = ref(true);
    const store = useStore();
    store.dispatch("setCategories")
      .then(() => {
        isLoading.value = false;
        document.dispatchEvent(new Event("render-complete"))
      })
      .catch(() => isLoading.value = false)

    store.dispatch("setWishlistCount");
      
    watchEffect(() => {
      import(`@/assets/scss/lazy/_carousel.css`);
    });
    
    return {
      isLoading
    }
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-weight: $light-font-weight;
}

html {
  width: 100%;
  font-size: 100%;
} /*16px*/

body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: $bold-font-weight !important;
  margin: 0px !important;
  color: color(blue);
}

h1 {
  font-size: $h1-size !important;
  line-height: calc(#{$h1-size} * 1.33) !important;

  @include media("<desktop-small") {
    font-size: $h2-size !important;
    line-height: calc(#{$h2-size} * 1.33) !important;
  }

  @include media("<tablet") {
    font-size: $h3-size !important;
    line-height: calc(#{$h3-size} * 1.33) !important;
  }

  @include media("<phone-large") {
    font-size: $h4-size !important;
    line-height: calc(#{$h4-size} * 1.33) !important;
  }
}

h2 {
  font-size: $h2-size !important;

  @include media("<desktop-small") {
    font-size: $h3-size !important;
  }

  @include media("<tablet") {
    font-size: $h4-size !important;
  }
  
}

h3 {
  font-size: $h3-size !important;

  @include media("<tablet") {
    font-size: $h4-size !important;
  }
}

h4 {
  font-size: $h4-size !important;
}

.form-control, textarea {
  color: color(blue) !important;
  border: 1px solid color(darkgrey) !important;
  border-radius: 0 !important;
}

.form-control:focus{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

textarea{
  resize: none !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  padding: 6px 12px !important;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

a, img{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

hr{
  background-color: color(darkgrey);
  margin: 12px 0 12px 0;
}

:root {
  accent-color: color(blue);
}

.c-toast-container{
  padding: 0 !important;
}

  .c-toast{
    padding: 10px 20px;
    border-radius: 0 !important;
    border: 0;
    margin: 15px $grid-margin-width 15px 0 !important;
  }

  .c-toast--success{
    //background-color: rgb(57, 193, 108) !important;
    background-color: rgb(119, 199, 84) !important;
  }

  .c-toast--error{
    background-color: color(red) !important;
  }

</style>
