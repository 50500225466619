<template>
  <section class="actions-section p-0" v-if="actions && actions.length > 0">
    <div class="d-flex justify-content-between action-navigation align-items-end">
      <div class="d-flex align-items-center">
        <h2>Akcije</h2>
        <span @click="moveLeft" ref="leftArrowActions" class="circle-arrow-wrapper">
          <svg class="circle-arrow-icon" ></svg>
        </span>
        <span @click="moveRight" ref="rightArrowActions" class="circle-arrow-wrapper">
          <svg class="circle-arrow-icon right-arrow-icon" ></svg>
        </span>
      </div>
      <router-link to="/proizvodi?akcije=true" class="look-everything-link">
        Pogledaj sve
        <svg class="right-long-arrow"></svg>
      </router-link>
    </div>
    <div class="carousel-wrapper">
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        style="cursor: pointer"
      >
        <Slide v-for="action in actions" :key="action.id">
          <ProductItem class="item" :product="action" />
        </Slide>
        <template #addons >
          <navigation>
            <template #next>
              <span ref="rightArrowActions"></span>
            </template>
            <template #prev>
              <span ref="leftArrowActions"></span>
            </template>
          </navigation>
        </template>
      </Carousel>
    </div>
  </section>
</template>
<script>
import ProductItem from "@/components/product/ProductItem";
import { ref, onMounted, computed } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { useStore } from "vuex";
import { SETTINGS, BREAKPOINTS } from "./carouselSettingsData";
import 'vue3-carousel/dist/carousel.css';

export default {
  components: { Carousel, Slide, Navigation, ProductItem },
  setup() {
    const store = useStore();
    const actions = computed(() => store.getters.getActionProducts);
    
    //hidden template elements left and right arrow on carousel
    const leftArrowActions = ref(null);
    const rightArrowActions = ref(null);
    //carousel default settings
    const settings = SETTINGS;
    //changes on carousel settings based on screen size
    const breakpoints = BREAKPOINTS;

    onMounted(() => {
      if (store.getters.getActionProducts.length < 1) {
        store.dispatch("setActionProducts");
      }
    });
    
    //go to next action product
    const moveLeft = () => {
      leftArrowActions.value.click();
    };

    //go to previous action product
    const moveRight = () => {
      rightArrowActions.value.click();
    };

    return {
      actions,
      settings,
      breakpoints,
      moveLeft,
      moveRight,
      leftArrowActions,
      rightArrowActions
    };
  },
};
</script>

<style lang="scss" scoped>

.action-navigation{
  padding: 0 $grid-margin-width;
}

  .circle-arrow-wrapper{
    @include noselect;
  }

    .circle-arrow-icon {
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin-left: 20px;
      @include background-svg($left-arrow-in-circle);

      @include media("<desktop-small") {
        display: none;
      }
    }

    .right-arrow-icon {
      transform: rotate(180deg);
    }

  .look-everything-link {
    color: color(darkgrey);
    text-decoration: none;
  }

    .right-long-arrow {
      @include icon;
      @include background-svg($right-long-arrow);
      height: 10px;
      width: 18px;
    }

.spinner-wrapper{
  padding: $grid-margin-width;
}

.carousel-wrapper {
  margin: 20px $grid-margin-width 30px $grid-margin-width;
  @include media("<=tablet-large") {
      margin: 10px 0px 40px $grid-margin-width;
  }
}

  .carousel__slide{
    padding: 10px 10px 10px 10px;
    transform: translateX(0);
  }

</style>
