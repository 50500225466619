<template>
  <div class="product align-items-center card text-center carousel__item" @mousedown="dragStart($event)" @mouseup="dragEnd($event)" v-on:click="navigateToProductDetailsPage()">
    <div v-if="product.actionPrice" class="discount-wrapper">
      <svg class="discount-banner-icon"></svg>
    </div>
    <span v-if="product.actionPrice" class="discount-amount">{{product.actionDiscount}}%</span>
    <div class="product-image-container">
      <img
      class="product-image"
      :src="imageUrl"
      :alt="product.name + ' ' + product.description"
      />
    </div>
    <div class="product-info">
      <h4 class="product-name">{{ product.name }}</h4>
      <p class="category-name">{{ product.categoryName }}</p>
      
    </div>
    <div class="product-price d-flex flex-column">
        <p v-if="product.actionPrice" class="old-price">{{ product.price}},00
          <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
          <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
        </p>
        <h4 class="action-price" v-if="product.actionPrice">
          {{ product.actionPrice}},00
          <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
          <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
        </h4>
        <h4 class="regular-price" v-if="!product.actionPrice">
          {{ product.price}},00
          <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
          <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
        </h4>
    </div>
    <base-button @click.stop="addToWishList()" buttonText="Sačuvaj" class="add-to-favourites-btn"></base-button>
  </div>
</template>

<script>
import BaseButton from "../../components/shared/BaseButton.vue";
import { useRouter } from "vue-router";
import { ref, inject } from "vue";
import { useStore } from "vuex";

export default {
  components: { BaseButton },
  props: {
    product: Object,
  },
  setup(props){
    const store = useStore();
    const imageUrl = process.env.VUE_APP_API_URL + "/" + props.product.imageUrl;
    const router = useRouter();
    const clientX1 = ref(0);
    const clientX2 = ref(0);

    const toast = inject('toast');

    const addToWishList = () => {
      let wishList = getWishList();
      if (!wishList) wishList = {};
      let count = 1;
      if (!wishList[props.product.id]) {
        wishList[props.product.id] = {};
      } else {
        count = wishList[props.product.id].count + 1;
      }

      wishList[props.product.id].count = count;
      wishList[props.product.id].name = props.product.name;
      wishList[props.product.id].categoryName = props.product.categoryName;
      wishList[props.product.id].price = props.product.actionPrice ? props.product.actionPrice : props.product.price;
      wishList[props.product.id].imageUrl = process.env.VUE_APP_API_URL + "/" + props.product.imageUrl;

      if(props.product.categoryName.toLowerCase().includes('pločice')){
        wishList[props.product.id].squaresInBox = props.product.characteristics['Kvadrata u kutiji'];
      }

      localStorage.setItem("vodotermWishList", JSON.stringify(wishList));
      store.dispatch("setWishlistCount");
      toast.success("Proizvod je dodat u listu želja");
    }

    const getWishList = () => {
      let parsedData = {};
      try {
        parsedData = JSON.parse(localStorage.getItem("vodotermWishList"));
      } catch (error) {
        // handle error
      }
      return parsedData;
    };

    const dragStart = (event) => {
      clientX1.value = event.clientX;
    }

    const dragEnd = (event) => {
      clientX2.value = event.clientX;  
    }

    const navigateToProductDetailsPage = () => {
      if(clientX1.value == clientX2.value)
        router.push(`/proizvodi/${props.product.id}`);
    }
    
    return {
      addToWishList,
      navigateToProductDetailsPage,
      imageUrl,
      dragStart,
      dragEnd
    };
  }
};
</script>

<style scoped lang="scss">

.card {
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0;
  padding: 0 30px;
  width: 100%;
  @include noselect;
  transition: box-shadow 0.3s ease-in-out;

  &:hover{
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }

}

  .discount-wrapper {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 108px;
    height: 108px;
  }

    .discount-banner-icon{
      @include icon;
      width: 108px;
      height: 108px;
      background-position: 0% 0%;
      @include background-svg($discount-icon);
    }

    .discount-amount{
      position: absolute;
      left: 22px;
      top: 22px;
      z-index: 10;
      color: white;
      font-weight: $bold-font-weight;
      font-size: $h4-size;
      transform: rotate(-45deg);
    }

  .product-image-container{
    margin-top: 30px;
    position: relative;
    display:flex;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    @include media('>=desktop-large'){
      padding-bottom: 10px;
      display: block;
      height: 236px;
    }
  }
  
    .product-image {
      object-fit: contain;
      background-size: contain;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;

      @include media('>=desktop-large'){
        height: 236px;
        position: unset;
      }
    }

  .product-info {
    margin-top: 10px;
    min-height: 240px;
  }

    .product-name{
      margin-bottom: 10px !important;
      height: 52px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      line-clamp: 2; 
      -webkit-box-orient: vertical;
    }

    .category-name {
      color: color(darkgrey);
      margin-bottom: 20px;
    }

  .product-price{
    position: absolute;
    bottom: 80px;
  }

    .action-price {
      color: color(red);
      font-weight: $light-font-weight !important;
    }

    .old-price {
      text-decoration: line-through;
      color: color(darkgrey);
      margin-bottom: 5px;
    }

    .regular-price {
      margin-top: 29px !important;
      color: color(darkgrey);
      font-weight: $light-font-weight !important;
    }

    .add-to-favourites-btn{
      width: 72.549%;
      position: absolute;
      bottom: 30px;
    }

</style>
