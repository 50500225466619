<template>
    <div 
        v-if="images && images.length > 0"
        id="gallery-slide" 
        class="gallery-slide"
        @mousedown="mouseDown($event)" 
        @mousemove="mouseMove($event)" 
        @mouseup="mouseUp($event)"
        @mouseleave="mouseLeave"
    >
        <div class="scrollable-area" id="scrollable-area">
            <img v-for="img in images" :key="img"
            draggable="false"
            :src="img.path"
            alt="Gallery photo small"
            class="gallery-img"
            :style="{ top: img.top, left: img.left, width: img.width, height: img.height, 'z-index': img.zindex}"
            @click="openImageViewer(img)"
            >
        </div>
        <ImageViewer :images="imagesFullPaths" ref="imageViewer"/>
    </div>
</template>

<script>
import ImageViewer from '@/components/shared/ImageViewer.vue';
import { ref, onMounted } from 'vue';
import { IMAGES } from "./ourWorkImages";

export default {
    components: { ImageViewer },
    setup(){

        const images = IMAGES;
        const imagesFullPaths = images.map(img => img.fullResPath);

        // Position of the scrolled gallery slide 
        const left = ref(0);
        // Position of the mouse on the screen
        const x = ref(0);
        const lastX = ref(0);
        // Difference between mouseDown position and mouseUp position
        const dx = ref(null);

        const gallerySlide = ref(null);
        const isMouseDownOnGallerySlide = ref(false);

        const imageViewer = ref(null);

        onMounted(() => {
            gallerySlide.value = document.getElementById('gallery-slide');
        })

        const mouseDown = (event) => {
            left.value = gallerySlide.value.scrollLeft; // The current scroll of the gallery slide
            x.value = event.clientX; // Current mouse position
            isMouseDownOnGallerySlide.value = true;
        }

        const mouseMove = (event) => {
            if(isMouseDownOnGallerySlide.value){
                // How far the mouse has been moved
                dx.value = event.clientX - x.value;
                // Scroll the element
                gallerySlide.value.scrollLeft = left.value - dx.value;
            }
        }

        const mouseUp = (event) => {
            isMouseDownOnGallerySlide.value = false;
            lastX.value = event.clientX;
        }

        const mouseLeave = () => {
            isMouseDownOnGallerySlide.value = false;
        }

        const openImageViewer = (img) => {
            if(lastX.value == x.value)
                imageViewer.value.openImageViewer(img.fullResPath);
        }

        return{
            images,
            imagesFullPaths,
            mouseDown,
            mouseMove,
            mouseUp,
            mouseLeave,
            openImageViewer,
            imageViewer
        }
    }
}
</script>

<style lang="scss" scoped>

.gallery-slide {
    overflow-x: scroll;
    cursor: grab;
    position: relative;
    height: 385px;
    margin-bottom: 20px;

    &:active{
        cursor: grabbing;
    }

    &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    &::-webkit-scrollbar-button {
        display: block;
        width: 19%;

        @include media("<tablet"){
            width: 15%;
        }

        @include media("<phone-large"){
            width: 8%;
        }
    }

    &::-webkit-scrollbar-track {
        background: color(lightgrey);
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        background-clip: padding-box;
    }
    
    &::-webkit-scrollbar-thumb {
        background: color(blue);
    }
}

    .scrollable-area{
        width: 3048px;
        margin: 0 $grid-margin-width;
        height: 100%;
        position: relative;
    }

        .gallery-img{
            cursor: pointer;
            position: absolute;
            object-fit: cover;
            transition: transform 0.35s ease-in-out;

            &:hover{
                transform: scale(1.05);
            }
        }

</style>