<template>
    <div>
        <div class="blue-wrapper d-flex flex-column">
            <h1>Odricanje od odgovornosti</h1>
            <span class="notice">
                Informacije koje pružamo na sajtu www.vodoterm.rs
                u daljem tekstu "sajt" su informativnog karaktera. O
                tome možete pročitati više na ovoj stranici. 
            </span>
        </div>
        <div class="wrapper">
            <span>
                Vodoterm DOO, u nastavku "Vodoterm", kao ni bilo koje treće like angažovano od strane sajta u kreiranju, postavljanju i održavanju sajta, ne garantuje tačnost, integritet niti kompletnost sadržaja koji se prikazuje na ovom sajtu, ili proizvoda, robe i usluga. Vodoterm ne daje nikakve garancije, bilo koje vrste, implicitne ili izražene, u pogledu tačnosti, validnosti, pouzdanosti, dostupnosti ili potpunosti informacija koje se nalaze na ovom sajtu.
                <br><br>
                Vodoterm se ni pod kojim okolnostima ne može smatrati odgovornim za bilo kakvu štetu ili gubitak bilo koje vrste, nastale kao rezultat korišćenja sajta ili oslanjanja na bilo koju informaciju datu na sajtu. Vaše korišćenje sajta i oslanjanje na bilo koju informaciju na sajtu je isključivo na Vaš sopstveni rizik. Svaka poseta sajtu www.vodoterm.rs, podrazumeva da ste uslove korišćenja koji se nalaze na ovoj stranici pročitali, iste razumeli i da se sa istima u potpunosti slažete.
            </span>
            <h4>Odricanje od odgovornosti spoljnih linkova na sajtu</h4>
            <span>
                Ovaj sajt može sadržati linkove ka drugim sajtovima i veb lokacijama kojima upravljaju treća lica. Ne preuzimamo nikakvu odgovornost za sadržaj koji se nalazi na spoljnim linkovima, jer na iste nemamo uticaja, niti proveravamo njihov sadržaj. Ne preuzimamo nikakvu odgovornost za bilo kakvo kršenje zakonskih odredbi ili povredi Vaših prava ili prava trećih lica ukoliko odlučite da odete na spoljni link. Ne garantujemo tačnost niti pouzdanost bilo kojih informacija koje nude sajtovi trećih lica i odbacujemo vezu sa sadržajem takvih stranica do kojih vode linkovi na našem sajtu. Ako koristite takve linkove, napuštate ovaj sajt i pristupate drugom sajtu na kome važe drugi uslovi korišćenja. Ovim potvrđujete da ste saglasni da Vodoterm nije odgovoran za dostupnost takvih spoljnih sajtova, za sadržaj, proizvode, usluge ili druge stvari koje se nalaze na tim sajtovima.
            </span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.blue-wrapper {
    padding: 0 $grid-margin-width;
    color: color(blue);
    background-color: rgb(244, 245, 249);
}

    h1{
        margin-top: 200px !important;
    }

    .notice{
        display: inline-block;
        margin-top: 30px;
        width: 400px;
        align-self: flex-end;
        text-align: justify;
        margin-bottom: 30px;

        @include media("<phone-large"){
            width: 100%;
        }
    }

.wrapper{
    padding: 30px $grid-margin-width;
    color: color(blue);
}

    h4{
        margin-top: 30px !important;
        margin-bottom: 20px !important;
    }

    span{
        display: inline-block;
        text-align: justify;
    }

</style>