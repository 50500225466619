<template>
  <footer>
    <div class="footer-body">
      <div class="grid">
        <div class="first-column-md">
          <h4>Proizvodi</h4>
          <div class="footer-info-container" v-for="category in categories" :key="category.id">
            <span @click="navigateToProductsPage(category.name)">{{ category.name }}</span>
          </div>
          <FooterAddress class="show-sm" />
        </div>
        <div>
          <FooterExplore />
          <FooterContact class="show-sm" />
          <FooterBusinessHours class="show-sm" />
          <FooterAddress class="show-md" />
        </div>
        <div class="third-column-md">
          <FooterContact />
          <FooterBusinessHours />
        </div>
      </div>
      <div class="icons">
        <!-- <svg class="instagram-icon"></svg> -->
        <a target="_blank" rel="nofollow" href="https://www.facebook.com/Vodoterm-Kragujevac-111629691197218/">
           <svg class="facebook-icon"></svg>
        </a>
      </div>
    </div>
    <FooterBottom />
  </footer>
</template>

<script>
import FooterBottom from './FooterBottom.vue'
import FooterContact from "../footer/FooterContact.vue";
import FooterBusinessHours from "../footer/FooterBusinessHours.vue";
import FooterAddress from "../footer/FooterAddress.vue";
import FooterExplore from "../footer/FooterExplore.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  components: {
    FooterExplore,
    FooterAddress,
    FooterBusinessHours,
    FooterContact, FooterBottom
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const categories = computed(() => {
      return store.getters.getCategories;
    });

    const navigateToProductsPage = (categoryName) => {
      router.push(`/proizvodi?kategorija=${categoryName}`);
    }

    return {
      categories,
      navigateToProductsPage
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/_footer.scss";

.footer-body {
  width: 100%;
  min-height: 341px;
  background: color(blue);
  padding: 30px $grid-margin-width;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  @include noselect;

  @include media("<desktop-small") {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include media("<tablet") {
    padding-left: 37px;
    padding-right: 37px;
  }
  
}

  .grid {
    display: grid;
    min-width: 60vw;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 80px;

    @include media("<desktop-small") {
      column-gap: 15px;
    }

    @include media("<tablet") {
      min-width: 80vw;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }

    @include media("<phone-medium") {
      grid-template-columns: 1fr;
    }
    
  }

    .footer-info-container {
      margin-bottom: 0px;
      margin-top: 8px;
      font-size: 14px;

      span{
        cursor: pointer;
      }
    }

    h4, .footer-info-container {
      color: white;
    }

    .show-sm {
      display: none;

      @include media("<tablet") {
        display: block;
      }

    }

    .show-md {
      @include media("<tablet") {
        display: none;
      }
    }

    .third-column-md {
      @include media("<tablet") {
        display: none;
      }
    }

  .icons{
    position: absolute;
    right: $grid-margin-width;
  }

    .instagram-icon {
      @include icon;
      width: 20px;
      height: 20px;
      @include background-svg($instagram-icon);
    }

    .facebook-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      @include background-svg($facebook-icon);
      margin-left: 10px;
    }

</style>
