import PublicModule from "../Public.vue";
import HomePage from "@/pages/HomePage";
import ContactPage from "@/pages/ContactPage";
import AboutPage from "@/pages/AboutPage";
import WishListPage from "@/pages/WishListPage";
import ProductsPage from "@/pages/ProductsPage";
import ProductDetailsPage from "@/pages/ProductDetailsPage";
import NotFoundPage from "@/pages/NotFoundPage";
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage";
import DisclaimerPage from "@/pages/DisclaimerPage";

const routes = 
{
    path: "/",
    component: PublicModule,
    children: [
      {
        path: "",
        name: "Home",
        component: HomePage,
        meta: {
          title: 'Vodoterm - Veliki izbor opreme za kupatilo',
          T: [
            {
              name: 'description',
              content: 'Veliki izbor opreme za kupatilo po povoljnim cenama u Kragujevcu. Plocice, baterije i slavine, bojleri, kupatilski namestaj, sanitarija...'
            },
            {
              property: 'og:description',
              content: 'Veliki izbor opreme za kupatilo po povoljnim cenama. Plocice, baterije i slavine, bojleri, kupatilski namestaj, sanitarija...'
            }
          ]
        }
      },
      {
        path: "o-nama",
        name: "About",
        component: AboutPage,
      },
      {
        path: "kontakt",
        name: "Contact",
        component: ContactPage,
      },
      {
        path: "lista-zelja",
        name: "Wishlist",
        component: WishListPage,
        meta: {
          title: "Lista želja",
          metaTags: [
            {
              name: "robot",
              content: "nofollow",
            },
          ]
        }
      },
      {
        path: "proizvodi",
        component: ProductsPage,
      },
      {
        path: "proizvodi/:id",
        component: ProductDetailsPage,
      },
      {
        path: "politika-privatnosti",
        component: PrivacyPolicyPage
      },
      {
        path: "odricanje-od-odgovornosti",
        component: DisclaimerPage
      },
      {
        path: "/:pathMatch(.*)*",
        component: NotFoundPage,
      }
    ],
};

export default routes;