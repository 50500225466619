<template>
  <div>
    <div v-if="isLoading" class="d-flex align-items-center justify-content-center vw-100 vh-100">
      <LoadingSpinner/>
    </div>
    <ProductCategories v-if="shouldShowProductCategories"/>
    <div v-if="!shouldShowProductCategories && !isLoading" class="product-page-wrapper">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between little-header">
          <p>Proizvodi {{filterByCategories ? title : ' - ' +categoryName}}</p>
          <p v-if="filteredProducts.length > 0">
            {{ stringAboutNumberOfShowenProducts }}
          </p>
        </div>
        <div class="d-flex no-content justify-content-center w-100" v-if="allProducts.length == 0">
          Trenutno nema proizvoda u izabranoj kategoriji.
        </div>
        <div v-else>
          <div class="filters-and-sorting d-flex flex-row align-items-center">
            <svg @click.stop="filtersAreOpen=true" class="filters-icon"></svg>
            <h3 @click.stop="filtersAreOpen=true">
              Filteri i sortiranje
            </h3>
          </div>
          <div class="products d-flex flex-row">
            <div class="filters">
              <ProductFilters
                :checkedFilters="selectedFilters"
                :filters="filters"
                :checkedCategories="selectedCategories"
                :categoriesFilters="categoriesFilters"
                @checked="checked($event)"
                @categoryChecked="categoryChecked($event)"
                @sortAsc="sortAsc()"
                @sortDesc="sortDesc()"
                @sortStandard="sortStandard()"
                @removeAllFilters="removeAllFilters()"
                :selectedSort="selectedSort"
              />
            </div>
            <div class="d-flex no-content justify-content-center w-100" v-if="filteredProducts.length == 0">
              Trenutno nema proizvoda u izabranim filterima.
            </div>
            <div v-else class="grid">
              <ProductItem
                v-for="product in productsOnPage"
                :key="product.id"
                :product="product"
                @click="redirectToProductDetails(product)"
                style="cursor: pointer"
              />
            </div>
            <div class="mobile-products">
              <ProductItemMobile
                v-for="product in productsOnPage"
                :key="product.id"
                :product="product"
              />
            </div>
          </div>
          <div class="d-flex flex-column align-items-center w-100">
            <BasePagination
              class="product-pagination"
              @change="changePage"
              :currentPage="currentPage"
              :totalPages="Math.floor((filteredProducts.length - 1) / 20) + 1"
            />
          </div>
        </div>
        
      </div>
    </div>
    <div class="aside-filters">
      <BaseBackdrop class="backdrop" :class="{'backdrop-visible': filtersAreOpen}"/>
      <div v-click-away="onClickAwaySideDrawer">
        <SideDrawer :isSideDrawerOpen="filtersAreOpen">
          <ProductFilters
            :checkedFilters="selectedFilters"
            :filters="filters"
            :checkedCategories="selectedCategories"
            :categoriesFilters="categoriesFilters"
            @checked="checked($event)"
            @categoryChecked="categoryChecked($event)"
            @sortAsc="sortAsc()"
            @sortDesc="sortDesc()"
            @sortStandard="sortStandard()"
            @removeAllFilters="removeAllFilters()"
            :selectedSort="selectedSort"
          />
        </SideDrawer>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue'
import ProductCategories from './ProductCategories.vue'
import ProductItemMobile from './ProductItemMobile.vue'
import SideDrawer from "@/components/shared/SideDrawer.vue";
import BaseBackdrop from "@/components/shared/BaseBackdrop.vue";
import ProductFilters from "./ProductFilters.vue";
import BasePagination from "@/components/product/BasePagination.vue";
import ProductItem from "@/components/product/ProductItem.vue";
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import axios from "axios";

const PRODUCTS_PER_PAGE = 20;

export default {
  components: {
    ProductItem,
    BasePagination,
    ProductFilters,
    BaseBackdrop,
    SideDrawer,
    ProductItemMobile,
    ProductCategories,
    LoadingSpinner
  },
  setup() {
    const title = ref('');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const allProducts = ref([]);
    const filteredProducts = ref([]);
    const productsOnPage = ref([]);
    const categoryName = ref(route.query.kategorija);
    const shouldShowProductCategories = ref(false);

    let isOnMountedFinished = false;
    const currentPage = ref(1);
    const selectedSort = ref("");
    const filters = ref({});
    const categoriesFilters = ref({});
    const selectedFilters = reactive({});
    const selectedCategories = ref(new Set());

    const filterByCategories = ref(false);
    const filterBySubCategories = ref(false);
    const categories = computed(() => {
      return store.getters.getCategories;
    });

    const filtersAreOpen = ref(false);

    watch(route, () => {
      const oldCategory = categoryName.value
      if(oldCategory != route.query.kategorija) {
        categoryName.value = route.query.kategorija;
        if(isOnMountedFinished) {
          initializeData();
        }
      }
    })

    onMounted(() => {
      initializeData();
    })

    const initializeData = () => {
      filterByCategories.value = false;
      filterBySubCategories.value = false;
      categoriesFilters.value = {};
      currentPage.value = route.query.page || 1;
      let promises = [];
       // if we should display all posible product categories
      if(route.fullPath == "/proizvodi"){
        shouldShowProductCategories.value = true;
      }
      // if we should display products of certain category
      else if (route.query.kategorija) {
        shouldShowProductCategories.value = false;
        filterBySubCategories.value = true;
        isLoading.value = true;
        const categoryId = getCategoryIdByName(route.query.kategorija);
        promises = [
          // Fetching all products
          axios.get(
            `${process.env.VUE_APP_API_URL}/api/Products?CategoryId=${categoryId}`
          ),
          // Fetching all product filters
          axios.get(
            `${process.env.VUE_APP_API_URL}/api/Products/category/${categoryId}/allFilters`
          ),
        ];
      }
      // if we should display products on action
      else if (route.query.akcije) {
        shouldShowProductCategories.value = false;
        filterByCategories.value = true;
        title.value = ' - Akcije';
        isLoading.value = true;
        // there is chance that action producta are already fetched
        if (store.getters.getActionProducts.length > 0) {
          setAndDisplayAllProductsAndFilteredProducts(store.getters.getActionProducts);
          isLoading.value = false;
        } else {
          promises = [
            axios.get(`${process.env.VUE_APP_API_URL}/api/Products?IsAction=true`),
          ];
        }
      }
      else if(route.query.search) {
        shouldShowProductCategories.value = false;
        filterByCategories.value = true;
        title.value = '';
        isLoading.value = true;
        const searchValue = route.query.search || '';
        promises = [
          axios.get(`${process.env.VUE_APP_API_URL}/api/Products?Search=${searchValue}`),
        ];
      }

      if (promises.length > 0) {
        Promise.all(promises).then((responses) => {
          const filters = responses.length > 1 ? responses[1].data : null;
          setAndDisplayAllProductsAndFilteredProducts(responses[0].data.data, filters);
          isLoading.value = false;
        });
      }

      if(!isOnMountedFinished) {
        isOnMountedFinished = true;
      }
    }


    const getCategoryIdByName = (name) => {
      for(let category of categories.value) {
        if(category.name === name) 
          return category.id;
        const childCategory = category.childCategories.find(c => c.name === name);
        if(childCategory) 
          return childCategory.id;
      }
    }

    // Setting fetched products and filters for them
    const setAndDisplayAllProductsAndFilteredProducts = (data, fetchedFilters) => {
      allProducts.value = data;
      filteredProducts.value = [...data];
      store.dispatch("setProductsOfSertainCategory", data);
      if (fetchedFilters) {
        filters.value = fetchedFilters;
      } else {
        filters.value = {};
      }

      if(filterByCategories.value || filterBySubCategories.value) {
        addCategoriesFilters(data, filterBySubCategories.value)
      }
      setProductsOnPage();
    };

    const addCategoriesFilters = (products, shouldFilterBySubCategories) => {
      let key = shouldFilterBySubCategories ? 'Podkategorije' : 'Kategorije';
      const categoriesObject = {};
      for(let product of products) {
        if(categoryName.value && product.categoryName == categoryName.value) {
          continue;
        }
        if(!categoriesObject[product.categoryName]) {
          categoriesObject[product.categoryName] = 0;
        }

        categoriesObject[product.categoryName] += 1;
      }

      categoriesFilters.value[key] = categoriesObject;
    }

    // Property for displaying what range and how many products are shown on current page
    const stringAboutNumberOfShowenProducts = computed(() => {
      const numberOfFilteredProducts = filteredProducts.value.length;
      const start = (currentPage.value - 1) * PRODUCTS_PER_PAGE + 1;
      let end = (currentPage.value - 1) * PRODUCTS_PER_PAGE + PRODUCTS_PER_PAGE;
      end = end <= numberOfFilteredProducts ? end : numberOfFilteredProducts;
      return `${start} - ${end} od ${numberOfFilteredProducts} artikala`;
    });

    const onClickAwaySideDrawer = () => {
      if(filtersAreOpen.value)
        filtersAreOpen.value = false;
    }

    // Callback function when the page is changed in BasePagination
    const changePage = (number) => {
      currentPage.value = number;
      setProductsOnPage();
      scrollTop();
      router.push({ path: '', query: {...route.query, page: number} })
      // if (number < 0) {
      //   currentPage.value = 1;
      //   setProductsOnPage();
      //   scrollTop();
      // }
    };

    // Slicing products on page based on pagination,
    const setProductsOnPage = () => {
      productsOnPage.value = filteredProducts.value.slice(
        (currentPage.value - 1) * PRODUCTS_PER_PAGE,
        (currentPage.value - 1) * PRODUCTS_PER_PAGE + PRODUCTS_PER_PAGE
      );
    };

    // Scrolls to top of the page when user changes page on pagination
    const scrollTop = () => {
      window.scrollTo(0, 0);
    };

    // Redirects user to ProductDetails
    const redirectToProductDetails = (item) => {
      router.push(`/proizvodi/${item.id}`);
    };

    // Callback function when filter is selected
    const checked = ({ event, name, value }) => {
      if (event.target.checked) {
        addSelectedFilter(name, value);
      } else {
        removeSelectedFilter(name, value);
      }
    };

    const categoryChecked = ({ event, value }) => {
      if (event.target.checked) {
        selectedCategories.value.add(value);
      } else {
        selectedCategories.value.delete(value);
      }

      filterProductsAndGoToFirstPage();
    }

    // Applying filter and reloading products
    const addSelectedFilter = (filterName, filterValue) => {
      if (!selectedFilters[filterName]) 
        selectedFilters[filterName] = new Set();
      selectedFilters[filterName].add(filterValue);
      filterProductsAndGoToFirstPage();
    };

    // Removing filter and reloading products
    const removeSelectedFilter = (filterName, filterValue) => {
      selectedFilters[filterName].delete(filterValue);
      filterProductsAndGoToFirstPage();
    };

    const filterProductsAndGoToFirstPage = () => {
      filterProducts();
      changePage(1);
      // if(currentPage.value != 1) {
      //     changePage(1);
      // } else {
      //     initializeData()
      // }
    };

    // Filtering products by every selected filter
    const filterProducts = () => {
      let products = [...allProducts.value];
      for (let filter in selectedFilters) {
        if (selectedFilters[filter] && selectedFilters[filter].size > 0)
          products = products.filter((p) =>
            selectedFilters[filter].has(p.characteristics[filter])
          );
      }

      if(selectedCategories.value && selectedCategories.value.size > 0) {
        products = products.filter(p => selectedCategories.value.has(p.categoryName));
      }
      filteredProducts.value = products;
    };

    // Standard sorting filter
    const sortStandard = () => {
      filterProductsAndGoToFirstPage();
    };

    // Descending by price sorting filter
    const sortDesc = () => {
      filteredProducts.value.sort((a, b) => {
        const priceA = a.actionPrice ? a.actionPrice : a.price;
        const priceB = b.actionPrice ? b.actionPrice : b.price;
        return priceB- priceA;
      });
      selectedSort.value = "desc";
      changePage(1);
      // if(currentPage.value != 1) {
      //     changePage(1);
      // } else {
      //     initializeData()
      // }
    };

    // Ascending by price sorting filter
    const sortAsc = () => {
      filteredProducts.value.sort((a, b) => {
        const priceA = a.actionPrice ? a.actionPrice : a.price;
        const priceB = b.actionPrice ? b.actionPrice : b.price;
        return priceA - priceB;
      });
      selectedSort.value = "asc";
      changePage(1);
      // if(currentPage.value != 1) {
      //     changePage(1);
      // } else {
      //     initializeData()
      // }
    };

    // Removing all filters
    const removeAllFilters = () => {
      for(let filterName in selectedFilters){
        if(selectedFilters[filterName].size > 0)
          selectedFilters[filterName].clear();
      }

      selectedCategories.value = new Set();
      filterProductsAndGoToFirstPage();
    }

    return {
      title,
      isLoading,
      shouldShowProductCategories,
      categoryName,
      currentPage,
      productsOnPage,
      allProducts,
      changePage,
      checked,
      categoryChecked,
      filterByCategories,
      filters,
      categoriesFilters,
      selectedFilters,
      selectedCategories,
      filteredProducts,
      stringAboutNumberOfShowenProducts,
      filtersAreOpen,
      onClickAwaySideDrawer,
      redirectToProductDetails,
      sortAsc,
      sortDesc,
      sortStandard,
      removeAllFilters,
      selectedSort
    };
  },
};
</script>

<style scoped lang="scss">

.product-page-wrapper{
  padding: 0 $grid-margin-width 30px $grid-margin-width;
}

  .no-content{
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .little-header {
    margin-top: 30px;
    margin-bottom: 15px;
    @include media("<phone-medium") {
      display: none !important;
    }

    p {
      color: color(darkgrey);
      font-size: 1rem;
      font-weight: $light-font-weight;
      margin: 0;
    }
  }

  .filters-and-sorting{
    cursor: pointer;
    margin: 30px 0 20px 0;
    margin-bottom: 20px;
    @include media(">=desktop-small") {
      display: none !important;
    }

    h3{
      font-size: $h4-size !important;
      @include noselect;
    }
  }

    .filters-icon {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      @include background-svg($filters-icon);
    }

  .filters{
    width: 275px;
    margin-right: 30px;
    @include media("<desktop-small") {
      display: none !important;
    }
  }

  .grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 22px;
    
    @include media("<desktop-medium") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media("<840px"){
      grid-template-columns: repeat(2, 1fr);
    }

    @include media("<phone-large") {
      display: none;
    }
  }

  .mobile-products{
    width: 100%;
    
    @include media(">=phone-large") {
      display: none;
    }
  }

    .mobile-product{
      cursor: pointer;
    }

  .product {
    margin-bottom: 22px;
  }

  .aside-filters {
    @include media(">=desktop-small") {
      display: none;
    }
  }

    .backdrop{
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    .backdrop.backdrop-visible{
      opacity: 1;
      visibility: initial;
    }

  .product-pagination{
    margin-top: 30px;
  }


</style>
