<template>
  <div>
    <div v-if="isLoading" class="vw-100 vh-100 d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
    <div v-if="!isLoading && !!product" class="product-details-page-wrapper">
      <div class="d-flex flex-row full-product-info-wrapper">
        <div class="images-container">
          <div class="main-image-container">
            <div v-if="product.actionPrice" class="discount-wrapper">
              <svg class="discount-banner-icon"></svg>
              <span class="discount-amount">{{product.actionDiscount}}%</span>
            </div>
            <img
              class="main-image"
              :src="selectedImage"
              alt="Gallery photo"
              @click="openImageViewer(selectedImage)"
            />
          </div>
          <div class="other-images">
            <img
              v-for="image in product.productImages"
              :key="image"
              class="other-image"
              :class="{'selected-other-image': baseUrl + '/' + image == selectedImage}"
              :src="baseUrl + '/' + image"
              @click="selectImage(image)"
            />
          </div>
        </div>
        <div class="product-info d-flex flex-column">
          <h3 class="product-name">{{ product.name }}</h3>
          <div class="product-code-container">
            <p>Sifra artikla {{product.productKey || '######'}}</p>
            <p>{{ product.categoryName }}</p>
          </div>
          <p class="product-description">
            {{ product.description }}
          </p>
          <div class="price-and-specification-wrapper-desktop">
            <ProductPrice :product="product" />
            <TilesCalculator :product="product" />
            <AddToWishList @wishlistChanged="onWishlistChanged" :product="product" />
            <ProductSpecifications :characteristics="product.characteristics" />
          </div>
        </div>
      </div>
      <div class="price-and-specification-wrapper-phone">
        <ProductPrice :product="product" />
        <TilesCalculator :product="product" />
        <AddToWishList @wishlistChanged="onWishlistChanged" :product="product" />
        <ProductSpecifications :characteristics="product.characteristics" />
      </div>
    </div>
    <div v-if="product">
      <ImageViewer :images="productImagesPaths" ref="imageViewer"/>
    </div>
  </div>
</template>

<script>
import ImageViewer from '@/components/shared/ImageViewer.vue'
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue'
import TilesCalculator from './TilesCalculator.vue'
import ProductPrice from "./ProductPrice.vue";
import ProductSpecifications from "./ProductSpecifications.vue";
import AddToWishList from "./AddToWishList.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";

export default {
  components: { AddToWishList, ProductSpecifications, ProductPrice, TilesCalculator, LoadingSpinner, ImageViewer },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const baseUrl = process.env.VUE_APP_API_URL;
    const selectedImage = ref("");
    const product = ref(null);
    const isLoading = ref(true);
    const imageViewer = ref(null);
    const productImagesPaths = ref([]);

    onMounted(() => {
      isLoading.value = true;
      let fetchedProduct = store.getters.getProducts.find(
        (p) => p.id == route.params.id
      );

      if (!fetchedProduct) {
        fetchedProduct = store.getters.getActionProducts.find(
          (p) => p.id == route.params.id
        );
      }

      if (!fetchedProduct) {
        axios
          .get(`${baseUrl}/api/Products/${route.params.id}`)
          .then((response) => {
            product.value = response.data;
            selectedImage.value = baseUrl + '/' + product.value.imageUrl;
            productImagesPaths.value = product.value.productImages.map(img => baseUrl + '/' + img);
            isLoading.value = false;
          })
          .catch(() => {
            isLoading.value = false;
            router.push('/Greska');
          });
      } else {
        product.value = fetchedProduct;
        selectedImage.value = baseUrl + '/' + product.value.imageUrl;
        productImagesPaths.value = product.value.productImages.map(img => baseUrl + '/' + img);
        isLoading.value = false;
      }
    });

    const selectImage = (image) => {
      selectedImage.value = baseUrl + '/' + image;
    };

    const onWishlistChanged = () => {
      store.dispatch("setWishlistCount");
    }

    const openImageViewer = (img) => {
      imageViewer.value.openImageViewer(img);
    }

    return {
      baseUrl,
      product,
      productImagesPaths,
      isLoading,
      selectedImage,
      imageViewer,
      selectImage,
      onWishlistChanged,
      openImageViewer
    };
  },
};
</script>

<style scoped lang="scss">

.product-details-page-wrapper{
  padding: 30px $grid-margin-width 30px $grid-margin-width;
}

  .look-everything-link {
    color: color(darkgrey);
    text-decoration: none;
    display: inline-block;
    margin-bottom: 20px;
  }

    .right-long-arrow {
      margin-right: 5px;
      @include icon;
      @include background-svg($right-long-arrow);
      height: 10px;
      width: 18px;
      transform: rotate(180deg);
    }

  .full-product-info-wrapper{
    @include media("<tablet"){
      display: block !important;
    }
  }
  
    .images-container {
      margin-right: 35px;
      @include media("<tablet"){
        margin: 0;
      }
    }

      .main-image-container {
        position: relative;
        margin-bottom: 20px;

        @include media("<tablet"){
          width: 55.55vw;
          height: 55.55vw;
          margin-left: auto;
          margin-right: auto;
        }

        @include media("<phone-medium"){
          width: 80vw;
          height: 80vw;
        }
      }

        .discount-wrapper {
          z-index: 1;
          position: absolute;
          left: 0;
          width: 108px;
          height: 108px;
        }

          .discount-banner-icon{
            @include icon;
            width: 108px;
            height: 108px;
            @include background-svg($discount-icon);
          }

          .discount-amount{
            position: absolute;
            left: 22px;
            top: 22px;
            z-index: 10;
            color: white;
            font-weight: $bold-font-weight;
            font-size: $h4-size;
            transform: rotate(-45deg);
          }

        .main-image{
          border: 1px solid color(lightgrey);
          width: 35.571vw;
          height: 35.571vw;
          object-fit: contain;
          cursor: pointer;

          @include media("<tablet"){
            width: 55.55vw;
            height: 55.55vw;
          }

          @include media("<phone-medium"){
            width: 80vw;
            height: 80vw;
          }
          
        }

      .other-images {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media("<tablet"){
          margin-bottom: 30px;
        }
      }

      .other-image {
        margin: 0 10px;
        width: 50px;
        height: 50px;
        object-fit: cover;
        cursor: pointer;
        
        @include media("<phone-medium"){
          margin: 0 5px;
          width: 30px;
          height: 30px;
        }
      }

      .selected-other-image{
        outline-color: color(lightblue);
        outline-style: solid;
      }

    .product-info{
      width: 100%;
      @include media("<tablet-large") {
        display: block !important;
      }
    }

      .product-name {
        color: color(blue);
        margin-bottom: 10px !important;

        @include media("<tablet") {
          text-align: center;
        }
      }

      .product-code-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include media("<tablet") {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        p {
          color: color(darkgrey);
          margin-bottom: 0 !important;
        }
      }

      .product-description {
        color: color(darkgrey);
        text-align: justify;
      }

      .price-and-specification-wrapper-desktop{
        @include media("<tablet-large"){
          display: none;
        }
      }

    .price-and-specification-wrapper-phone{
      @include media(">=tablet-large"){
        display: none;
      }
    }

</style>
