<template>
    <div class="footer-bottom d-flex justify-content-between">
        <div class="company">
            Copyright © 2022 Vodoterm. Sva prava zadržana
        </div>
        <div class="links d-flex">
            <div class="link" @click="navigateToPrivacyPolicyPage()">
                Politika privatnosti
            </div>
            <div class="link" @click="navigateToDisclaimerPage()">
                Odricanje od odgovornosti
            </div>
        </div>
    </div>
    <div class="credits">
        <div>
            Made by 
            <a target="_blank" rel="nofollow" href="https://www.linkedin.com/in/aleksavujisic/"><span class="author">A. Vujisić</span></a>
            , 
            <a target="_blank" rel="nofollow" href="https://www.linkedin.com/in/aleksavujisic/"><span class="author">J. Radović</span></a>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {

    setup(){

        const router = useRouter();

        const navigateToPrivacyPolicyPage = () => {
            router.push('/politika-privatnosti');
        }

        const navigateToDisclaimerPage = () => {
            router.push('/odricanje-od-odgovornosti');
        }

        return {
            navigateToPrivacyPolicyPage,
            navigateToDisclaimerPage
        }
    }

}
</script>

<style lang="scss" scoped>

.footer-bottom{
  background-color: rgb(17, 34, 78);
  color: white;
  font-size: 14px;
  padding: 20px $grid-margin-width;

  @include media("<phone-large"){
    flex-direction: column !important;
  }
}

  .company{
    margin-top: auto;
    @include media("<phone-large"){
      order: 2;
      margin-top: 8px;
    }
  }

  .links{
    @include media("<tablet"){
      flex-direction: column !important;
    }
  }

    .link{
      margin-left: 50px;
      cursor: pointer;
      @include media("<tablet"){
        margin-left: 0px;
        margin-bottom: 8px;
      }

      &:last-child{
        margin-bottom: 0px !important;
      } 
    }


.credits{
  background-color: rgb(14, 25, 54);
  color: white;
  font-size: 14px;
  padding: 20px $grid-margin-width;

  div{
    text-align: end;
  }
}

  .author{
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }

</style>