export default {
  getActionProducts(state) {
    return state.actionProducts;
  },

  getCategories(state) {
    return state.categories;
  },

  //not all products, only stored products
  getProducts(state) {
    return state.products;
  },

  getWishlistCount(state) {
    return state.wishlistCount;
  }
};
