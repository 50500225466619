<template>
  <header>
    <nav class="navbar navbar-expand-xl navbar-light">
      <img src="@/assets/logo.png" class="navbar-logo" alt="Vodoterm logo" @click="navigateHome()"/>
      <img src="@/assets/logo-small.png" class="navbar-logo-small" alt="Vodoterm logo" @click="navigateHome()"/>
      <div>
        <div class="icons-wrapper d-xl-none d-inline-block">
          <svg
            @click="navigateToWishList"
            class="wish-list-icon me-3"
            :class="{ play: wishListOpen }"
            alt="Lista želja"
          ></svg>
          <div class="wishlist-count" :class="{'wishlist-count-visible' : wishlistCount > 0}" @click="navigateToWishList()">
            <span>{{wishlistCount}}</span>
          </div>
          <svg class="search-icon me-3" alt="Pretraži" @click="toggleMobileSearchWindow"></svg>
        </div>

        <button
          class="navbar-toggler"
          type="button"
        >
          <svg
            @click="toggleMobileNavbar"
            class="nav-menu-icon"
            :class="{ play: navbarOpen, unplay: !navbarOpen }"
          ></svg>
        </button>
      </div>

      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarToggler"
      >
        <ul class="navbar-nav mr-auto mt-2 mt-xl-0">
          <li class="nav-item active"> 
            <RouterLink 
              class="nav-link"
              :to="'/'"
              @mouseover="closeCategoriesMenu"
              >Početna</RouterLink
            >
            <div class="anker-underline"></div>
          </li>
          <li class="nav-item dropdown">
            <RouterLink
              class="nav-link"
              id="navbarDropdown"
              @mouseover="openCategoriesMenu"
              @click="closeCategoriesMenu"
              to="/proizvodi"
              exact-path
              >Proizvodi</RouterLink
            >
            <div class="anker-underline"></div>
            <div
              v-if="categoriesMenuOpen"
              class="categories-menu"
              aria-labelledby="navbarDropdown"
              @mouseleave="closeCategoriesMenu"
            >
              <CategoriesMenu @onCloseCategoriesMenu="closeCategoriesMenu" />
            </div>
          </li>
          <li class="nav-item">
            <RouterLink
              class="nav-link"
              to="/o-nama"
              @mouseover="closeCategoriesMenu"
              >O Nama</RouterLink
            >
            <div class="anker-underline"></div>
          </li>
          <li class="nav-item">
            <RouterLink
              class="nav-link"
              to="/kontakt"
              @mouseover="closeCategoriesMenu"
              >Kontakt</RouterLink
            >
            <div class="anker-underline"></div>
          </li>
        </ul>
        <div class="wrapper d-flex">
          <svg
            @click="navigateToWishList()"
            class="wish-list-icon me-3"
            :class="{ play: wishListOpen }"
            alt="Lista želja"
          ></svg>
          <div class="wishlist-count" :class="{'wishlist-count-visible' : wishlistCount > 0}" @click="navigateToWishList()">
            <span>{{wishlistCount}}</span>
          </div>
          <form @submit.prevent="searchProducts" class="form-inline d-flex my-2 my-md-0">
            <div class="search-field-wrapper mr-sm-2 me-2 align-items-center">
              <svg class="search-icon" alt="Pretraži"></svg>
              <input
                class="form-control search-field"
                type="search"
                placeholder="Pretraga sajta..."
                aria-label="Search"
                :value="searchValue"
                @input="onSearch"
              />
              <SearchWindow :searchString="searchValue" />
            </div>
            <base-button :buttonText="'Pretraži'" type="submit"></base-button>
          </form>
        </div>
      </div>
    </nav>
    <div class="header-space"></div>
  </header>
  <div v-if="categoriesMenuOpen" class="categories-menu-backdrop">
    <BaseBackdrop @mouseover="closeCategoriesMenu" :topPosition="'70'" />
  </div>
  <div class="mobile">
    <MobileNavbar @closed="navbarOpen = false" :isVisible="navbarOpen"/>
    <MobileSearchWindow @closed="mobileSearchWindowOpen = false" :isVisible="mobileSearchWindowOpen"/>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import MobileSearchWindow from './MobileSearchWindow.vue'
import MobileNavbar from './MobileNavbar.vue'
import SearchWindow from './SearchWindow.vue'
import CategoriesMenu from "./CategoriesMenu.vue";
import { useRouter, onBeforeRouteUpdate } from 'vue-router';
import BaseBackdrop from "@/components/shared/BaseBackdrop.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
export default {
  components: { BaseBackdrop, CategoriesMenu, SearchWindow, BaseButton, MobileNavbar, MobileSearchWindow},
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchValue = ref('');
    const categoriesMenuOpen = ref(false);
    const navbarOpen = ref(false);
    const mobileSearchWindowOpen = ref(false);
    const wishListOpen = ref(false);

    const wishlistCount = computed(() => store.getters.getWishlistCount);

    onMounted(() => {
      if(router.currentRoute.value.path.includes("lista-zelja"))
        wishListOpen.value = true;
    })

    const navigateHome = () => {
      mobileSearchWindowOpen.value = false;
      navbarOpen.value = false;
      router.push({ path: '/' });
    }

    const closeCategoriesMenu = () => {
      categoriesMenuOpen.value = false;
    };

    const openCategoriesMenu = () => {
      categoriesMenuOpen.value = true;
    };

    const onSearch = (e) => {
      const searchString = e.target.value;
      searchValue.value = searchString;
    }

    const toggleMobileNavbar = () => {
      mobileSearchWindowOpen.value = false;
      navbarOpen.value = !navbarOpen.value;
    }

    const navigateToWishList = () => {
      mobileSearchWindowOpen.value = false;
      navbarOpen.value = false;
      wishListOpen.value = true;
      router.push("/lista-zelja");
    }

    const toggleMobileSearchWindow = () => {
      navbarOpen.value = false;
      mobileSearchWindowOpen.value = !mobileSearchWindowOpen.value;
    }

    const searchProducts = () => {
      if(searchValue.value) {
        const teampSearchValue = searchValue.value;
        searchValue.value = '';
        router.push(`/proizvodi?search=${teampSearchValue}`);
      }
    }

    onBeforeRouteUpdate(async (to) => {
      if(!to.path.includes("lista-zelja"))
        wishListOpen.value = false;
    })

    return {
      navigateHome,
      categoriesMenuOpen,
      navbarOpen,
      mobileSearchWindowOpen,
      wishListOpen,
      closeCategoriesMenu,
      openCategoriesMenu,
      searchValue,
      onSearch,
      toggleMobileNavbar,
      toggleMobileSearchWindow,
      navigateToWishList,
      searchProducts,
      wishlistCount
    };
  },
};
</script>

<style lang="scss" scoped>

.header-space{
  height: 70px;
  width: 100%;
}

.mobile {
  @include media(">=desktop-small"){
    display: none;
  }
}

.navbar {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 70px;
  padding: 20px 0px;
  background-color: white;
  border-bottom: 1px solid color(lightgrey);
}

  .icons-wrapper{
    position: relative;
  }

  .categories-menu {
    position: absolute;
    z-index: 1000;
    border: 0px;
    border-radius: 0;
    padding: 0px;
    top: 50px !important;
    max-height: calc(100vh - 70px);
    @include scrollbar;
    overflow-y: auto;
    background: white;
    display: block;
    margin-top: 0 !important;
  }

.nav-link:hover {
  color: color(blue);
}

.router-link-exact-active {
  color: color(blue) !important;
  font-weight: bold;
}

@include media(">=desktop-small") {
  .router-link-exact-active + .anker-underline {
    height: 5px;
    background: color(blue);
    top: 23px;
    position: relative;
    width: 70%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.navbar-logo {
  height: 30px;
  margin-left: $grid-margin-width;
  cursor: pointer;
  @include media(">=desktop-small") {
    margin-right: 50px;
  }

  @include media("<phone-small") {
    display: none;
  }
}

.navbar-logo-small{
  height: 30px;
  margin-left: $grid-margin-width;
  cursor: pointer;
  @include media(">=phone-small") {
    display: none;
  }
}

.navbar-toggler {
  margin-right: $grid-margin-width;
  padding: 0px;
  border: 0px;
  box-shadow: none !important;
}

.nav-menu-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  @include background-svg($nav-menu-icon);

  animation-duration: 300ms;
  animation-timing-function: steps(18);
  animation-fill-mode: forwards;
}

.navbar-collapse {
  padding-right: $grid-margin-width;
}

  .navbar-nav{
    height: 29px;
  }

.pointer {
  cursor: pointer;
}

.nav-item {
  margin-right: 30px;

  @include media("<desktop-small") {
    margin-right: 0px;
  }
}

.nav-item:last-child {
  margin-right: 0px;
}

.nav-link {
  padding: 0px !important;

  @include media("<desktop-small") {
    display: flex;
    justify-content: flex-end;
    margin: 3px 0;
  }
}

.wrapper {
  position: relative;
  justify-content: end;
  align-items: center;
  @include media("<desktop-small") {
    display: none !important;
  }
}

.wish-list-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  @include background-svg($wish-list-icon);

  animation-duration: 500ms;
  animation-timing-function: steps(18);
  animation-fill-mode: forwards;
}

.wishlist-count {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: color(darkgrey);
  position: absolute;
  top: 0px;
  left: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;

  @include media("<desktop-small") {
    top: -5px;
  }

  span {
    display: inline-block;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
}

.wishlist-count.wishlist-count-visible{
  transition: all 0.2s ease-in-out;
  opacity: 1;
  visibility: initial;
}

  .search-icon {
    cursor: pointer;
    width: 22px;
    height: 22px;
    @include background-svg($search-icon);
    @include media(">=desktop-small"){
      display: none;
    }
  }

  .search-field-wrapper{
    position: relative;
  }

    .search-field {
      @include background-svg($search-icon);
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 42px;
      border-radius: 0px;
      box-shadow: none !important;
    }

  .play {
    @keyframes play18 {
      0% {
        background-position: 0px 0px;
      }
      100% {
        background-position: -432px 0px;
      }
    }
    animation-name: play18;
  }

.categories-menu-backdrop{
  @include media("<desktop-small"){
    display: none;
  }
}

</style>
