<template>
  <div class="wrapper">
    <span @click="removeAllFilters" class="remove-filters">
      Ukloni sve filtere
    </span>
    <hr>
    <h5>Sortiraj</h5>
    <div class="filter-values">
      <label>
        <input
          type="radio"
          class="radio-button"
          name="sort"
          checked
          @click="sortStandard()"
          :value="selectedSort !== 'desc' && selectedSort !== 'asc'"
        />
        Standardno
      </label>
    </div>
    <div class="filter-values">
      <label>
        <input
          type="radio"
          class="radio-button"
          name="sort"
          @click="sortDesc()"
          :value="selectedSort === 'desc'"
        />
        Po ceni - opadajuce
      </label>
    </div>
    <div class="filter-values">
      <label>
        <input
          type="radio"
          class="radio-button"
          name="sort"
          @click="sortAsc()"
          :value="selectedSort === 'asc'"
        />
        Po ceni - rastuce
      </label>
    </div>
    <!-- Categories Filters -->
    <div
      v-for="(characteristicValue, characteristicName) in categoriesFilters"
      :key="characteristicName"
    >
      <template v-if="categoriesFilters[characteristicName] && Object.values(categoriesFilters[characteristicName]).length > 0">
        <h5>{{characteristicName}}</h5>
        <div class="filter-values"
          v-for="(categoryCount, categoryName) in categoriesFilters[characteristicName]"
          :key="categoryName"
        >
          <label>
            <input
              class="checkbox"
              type="checkbox"
              @change="onCategoryChecked($event, categoryName)"
              :id="[`${characteristicName}${characteristicValue}`]"
              :checked="
                checkedCategories.has(categoryName)
              "
            />
            {{ categoryName }} ({{ categoryCount }})
          </label>
        </div>
      </template>
    </div>
    <!-- End of categores filters -->
    <div
      v-for="(characteristicValues, characteristicName) in filters"
      :key="characteristicName"
    >
      <h5>{{ characteristicName }}</h5>
      <div
        class="filter-values"
        v-for="(characteristicCount, characteristicValue) in filters[
          characteristicName
        ]"
        :key="characteristicValue"
      >
      <label>
          <input
            class="checkbox"
            type="checkbox"
            @change="onChecked($event, characteristicName, characteristicValue)"
            :id="[`${characteristicName}${characteristicValue}`]"
            :checked="
              checkedFilters[characteristicName] &&
              checkedFilters[characteristicName].has(characteristicValue)
            "
          />
          {{ characteristicValue }} ({{ characteristicCount }})
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    filters: Object,
    categoriesFilters: Object,
    checkedFilters: Object,
    checkedCategories: Object,
    selectedSort: String,
  },
  emits: ["checked", "categoryChecked", "sortAsc", "sortDesc", "sortStandard", "removeAllFilters"],
  setup(props, context) {
    const isPageFiltered = ref(false);

    const onChecked = (event, name, value) => {
      context.emit("checked", { event, name, value });
      checkIfPageIsFiltered();
    };

    const onCategoryChecked = (event, value) => {
      context.emit("categoryChecked", { event, value });
      checkIfPageIsFiltered();
    }

    const checkIfPageIsFiltered = () => {
      for (var filter in props.checkedFilters){
        if(props.checkedFilters[filter].size > 0){
          isPageFiltered.value = true;
          return;
        }
      }
      isPageFiltered.value = false;
    }

    const sortDesc = () => {
      context.emit("sortDesc");
    };

    const sortAsc = () => {
      context.emit("sortAsc");
    };

    const sortStandard = () => {
      context.emit("sortStandard");
    };

    const removeAllFilters = () => {
        context.emit("removeAllFilters");
    }

    return {
      isPageFiltered,
      onChecked,
      onCategoryChecked,
      sortDesc,
      sortAsc,
      sortStandard,
      removeAllFilters
    };
  },
};
</script>

<style scoped lang="scss">

.remove-filters{
  cursor: pointer;
  color: color(darkgrey);

  @include noselect;
}

hr{
  background-color: color(lightgrey);
  margin: 10px 0 10px 0;
}

.wrapper > h5{
  margin-top: 0 !important;
}

h5 {
  color: color(blue);
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0 5px 0 !important; 
}

.filter-values {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: color(darkgrey);
}

  label{
    cursor: pointer;
    display: flex;
    align-items: center;
  }

.checkbox {
  margin-right: 8px;
  cursor: pointer;
  margin-left: 2px;
  box-shadow: none !important;
  min-width: 20px;
  height: 20px;
}

.radio-button {
  margin-right: 8px;
  cursor: pointer;
  margin-left: 2px;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
}

.selected-checkbox {
  background: color(blue) !important;
}
</style>
