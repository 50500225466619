<template>
  <div class="product-categories-wrapper">
    <div class="grid">
      <div class="product-category-card d-flex flex-column" v-for="category in categories" :key="category.id">
        <div class="d-flex category">
          <div class="icon-wrapper d-flex justify-content-center align-items-center" :style="{ 'background-color': hexColors[category.id-1]}">
            <svg @click="navigateToCategory(category.name)" class="icon" :class="[category.svgIcon]"></svg>
          </div>
          <div class="d-flex flex-column justify-content-between">
            <span @click="navigateToCategory(category.name)" class="category-name">{{category.name}}</span>
          </div>
        </div>
        <div class="grey-text" v-for="childCategory in category.childCategories" :key="childCategory.id">
          <span @click="navigateToCategory(childCategory.name)">{{childCategory.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
export default {
  
  setup(){
    const store = useStore();
    const router = useRouter();
    const categories = computed(() => {
      return store.getters.getCategories;
    });
    const hexColors = ref([]);
    hexColors.value.push("#E7EEFF");
    hexColors.value.push("#E7FFF8");
    hexColors.value.push("#F2EAB7");
    hexColors.value.push("#F3D7DA");
    hexColors.value.push("#E4CFFC");
    hexColors.value.push("#E2E2E2");
    hexColors.value.push("#F8D2AD");
    hexColors.value.push("#E3F1CD");
    hexColors.value.push("#A1D5F8");
    hexColors.value.push("#D1C4BF");

    const navigateToCategory = (categoryName) => {
      router.push(`/proizvodi?kategorija=${categoryName}`);
    }

    return {
      categories,
      hexColors,
      navigateToCategory
    }
  }   
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/_category-menu.scss";

.product-categories-wrapper{
  padding: 30px $grid-margin-width;
}

  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 22px;
    
    @include media("<tablet-large") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media("<phone-large"){
      grid-template-columns: repeat(1, 1fr);
    }

  }

    .product-category-card{
      width: 100%;
      background-color: white;
      padding: 30px;
      margin-bottom: 30px;
      @include shadow;
      @include media("<phone-small"){
        padding: 15px;
      }
    }

      .category{
        margin-bottom: 20px;
      }

        .icon-wrapper{
          padding: 14px;
          margin-right: 20px;
        }

          .icon {
            width: 40px;
            height: 40px;
            background-position-x: 50%;
          }

          .toilets-svg {
            @include background-svg($sanitation);
            @include icon;
          }

          .toilet-bowl-svg {
            @include background-svg($toilet-bowl-colored);
            @include icon;
          }

          .tiles-svg {
            @include background-svg($tiles-colored);
            @include icon;
          }

          .haberdashery-svg {
            @include background-svg($haberdashery-colored);
            @include icon;
          }

          .pipe-svg {
            @include background-svg($pipe-colored);
            @include icon;
          }

          .batery-svg {
            @include background-svg($battery-colored);
            @include icon;
          }

          .shower-svg {
            @include background-svg($shower-colored);
            @include icon;
          }

          .furniture-svg {
            @include background-svg($furniture-colored);
            @include icon;
          }

          .heater-svg {
            @include background-svg($heater-colored);
            @include icon;
          }

          .instalations-svg {
            @include background-svg($instalations-colored);
            @include icon;
          }

          .glue-svg{
            @include background-svg($glue-colored);
            @include icon;
          }

        .category-name{
          font-size: $h4-size;
          font-weight: bold;
          cursor: pointer;
          display: inline;
          color: color(blue);
          
          @include media("<tablet-large"){
            font-size: 1rem;
          }
        }

      .grey-text{
        color: color(darkgrey);
        margin-bottom: 10px;
        cursor: pointer;
        transition: color 0.2s ease-in;

        &:hover{
          color: color(blue);
        }

        :last-child{
          margin-bottom: 0;
        }
      }

</style>