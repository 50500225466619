<template>
    <div class="d-flex contact-us-wrapper justify-content-between align-items-center">
        <div class="d-flex flex-column">
            <h3>
                Imate neko pitanje?
            </h3>
            <span>Javite nam se i učinićemo sve što možemo da Vam pomognemo.</span>
        </div>
        <div class="button-wrapper">
            <base-button @click="navigateToContactPage()" class="button" :buttonText="'Kontaktirajte nas'"></base-button>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue'
import { useRouter } from 'vue-router';
export default {
    components: { BaseButton },
    setup(){
        
        const router = useRouter();
        const navigateToContactPage = () => {
            router.push('kontakt');
        }

        return {
            navigateToContactPage
        }
    }
}
</script>

<style lang="scss" scoped>

.contact-us-wrapper{
    padding: 30px $grid-margin-width;
    background-color: color(lightgrey);

    @include media("<tablet-large") {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}

    h3{
        margin-bottom: 10px !important;
        @include media("<tablet") {
            margin-bottom: 5px !important;
        }
    }

    span{
        color: color(blue);
        display: block;
        
        @include media("<tablet") {
            font-size: 1rem !important;
        }
    }

    .button-wrapper{
        @include media("<tablet-large") {
            margin-top: 10px !important;
        }
    }

        .button{
            width: 225px;
            height: 50px;

            @include media("<phone-small") {
                width: 100%;
            }
        }

</style>