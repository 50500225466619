<template>
  <div class="categories-container">
    <div class="parent-categories">
      <span class="title">Proizvodi</span>
      <router-link :to="{path: '/proizvodi', query: {kategorija: category.name}}" 
          v-for="category in categories"
          :key="category.id"
      >
        <div
          class="category d-flex align-items-center justify-content-between"
          @click="redirectToProductsPage()"
          :class="{'parent-category-hovered': !!hoveredCategory && hoveredCategory.id == category.id}"
          @mouseover="onCategoryHover(category)"
        >
          <div>
            <svg class="icon" :class="[category.svgIcon, hoveredCategory && hoveredCategory.id == category.id ? 'active-svg' : '']"></svg>
            <span>{{ category.name }}</span>
          </div>
          <svg v-if="hoveredCategory && hoveredCategory.id === category.id " class="right-arrow"></svg>
        </div>
      </router-link>
    </div>
    <div class="child-categories" v-if="!!hoveredCategory">
      <span class="title-child">{{ hoveredCategory.name }}</span>
      <router-link :to="{path: '/proizvodi', query: {kategorija: childCategory.name}}" 
        v-for="childCategory in hoveredCategory.childCategories"
        :key="childCategory.id"
      >
        <div
          class="child-category d-flex align-items-center"
          @click="redirectToProductsPage()"
        >
          {{ childCategory.name }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";

export default {
  setup(props, context) {
    const store = useStore();
    const hoveredCategory = ref(null);
    const categories = computed(() => {
      return store.getters.getCategories;
    });

    const onCategoryHover = (category) => {
      hoveredCategory.value = category;
    };

    const onParagraphHover = (category) => {
      if (hoveredCategory.value && hoveredCategory.value.id != category.id) {
        hoveredCategory.value = null;
      }
    };

    const redirectToProductsPage = () => {
      context.emit('onCloseCategoriesMenu');
    }

    return {
      categories,
      onCategoryHover,
      hoveredCategory,
      onParagraphHover,
      redirectToProductsPage
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/_category-menu.scss";

a {
  text-decoration: none;
}

.categories-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

  .title {
    display: inline-block;
    font-weight: bold;
    color: color(blue);
    margin: 21px 0 30px 72px;
  }

  .parent-categories {
    background: color(lightgrey);
    border-right: 0.5px solid color(darkgrey);
    width: 23.5vw;
  }

    .category {
      color: color(darkgrey);
      background: color(lightgrey);
      padding-left: 37px;
      cursor: pointer;
      height: 43px;

      &:hover {
        background: white;
        color: color(blue);

        .icon{
          background-color: white;
        }
      }
    }

      .icon {
        width: 25px;
        height: 25px;
        background: color(lightgrey);
        background-position-x: 50%;
        margin-right: 10px;
      }

      .batery-svg {
        @include background-svg($battery);
        @include icon;
      }

      .shower-svg {
        @include background-svg($shower);
        @include icon;
      }

      .haberdashery-svg {
        @include background-svg($haberdashery);
        @include icon;
      }

      .furniture-svg {
        @include background-svg($furniture);
        @include icon;
      }

      .heater-svg {
        @include background-svg($heater);
        @include icon;
      }

      .toilets-svg {
        @include background-svg($sanitation);
        @include icon;
      }

      .toilet-bowl-svg {
        @include background-svg($toilet-bowl);
        @include icon;
      }

      .tiles-svg {
        @include background-svg($tiles);
        @include icon;
      }
      
      .pipe-svg {
        @include background-svg($pipe);
        @include icon;
      }

      .instalations-svg {
        @include background-svg($instalations);
        @include icon;
      }

      .glue-svg{
        @include background-svg($glue);
        @include icon;
      }

      .active-svg{
          background-color: white;
        }

      .batery-svg.active-svg{
        @include background-svg($battery-blue);
      }

      .shower-svg.active-svg{
        @include background-svg($shower-blue);
      }

      .haberdashery-svg.active-svg{
        @include background-svg($haberdashery-blue);
      }

      .furniture-svg.active-svg{
        @include background-svg($furniture-blue);
      }

      .heater-svg.active-svg{
        @include background-svg($heater-blue);
      }

      .toilets-svg.active-svg{
        @include background-svg($sanitation-blue);
      }

      .toilet-bowl-svg.active-svg{
        @include background-svg($toilet-bowl-blue);
      }

      .tiles-svg.active-svg{
        @include background-svg($tiles-blue);
      }

      .pipe-svg.active-svg{
        @include background-svg($pipe-blue);
      }

      .instalations-svg.active-svg{
        @include background-svg($instalations-blue);
      }

      .glue-svg.active-svg{
        @include background-svg($glue-blue);
        @include icon;
      }

    .parent-category-hovered {
      background: white;
      color: color(blue);
    }

      .right-arrow {
        width: 9px;
        height: 16px;
        margin-right: 1.85vw;
        @include icon;
        @include background-svg($right-arrow);
      }

  .child-categories {
    height: 100%;
    background: white;
    width: 23.5vw;
  }

    .title-child{
      display: inline-block;
      font-weight: bold;
      color: color(blue);
      margin: 21px 0 30px 37px;
    }

    .child-category {
      color: color(darkgrey);
      background: white;
      padding-left: 37px;
      cursor: pointer;
      height: 43px;
      &:hover{
        background: color(lightgrey);
        color: color(blue);
      }
    }


</style>
