import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toaster from '@meforma/vue-toaster';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import store from "./store";
import VueClickAway from "vue3-click-away";

const app = createApp(App);

app.use(store);
app.use(Toaster).provide('toast', app.config.globalProperties.$toast);
app.use(VueClickAway);
app.use(router).mount("#app");
