import axios from "axios";

export default {
  setActionProducts(context) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}/api/Products?IsAction=true`)
      .then((response) => {
        context.commit("setActionProducts", { value: response.data.data });
      });
  },

  setCategories(context) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}/api/Categories`)
      .then((response) => {
        context.commit("setCategories", { value: response.data });
      });
  },

  setProductsOfSertainCategory(context, products) {
    context.commit("setProductsOfSertainCategory", { value: products });
  },

  deleteProduct(context, productId) {
    return axios
      .delete(`${process.env.VUE_APP_API_URL}/api/Products/${productId}`)
      .then(() => {
        context.commit("deleteProduct", {value: productId});
      });
  },

  setWishlistCount(context) {
    let count = 0;
    try {
      const wishlist = JSON.parse(localStorage.getItem("vodotermWishList"));
      if(wishlist) {
        count = Object.values(wishlist).length;
      }
    } catch (error) {
      // do nothing
    }
    context.commit("setWishlistCount", {value: count});
  }
};
