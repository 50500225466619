<template>
  <div class="vibrating-buttons">
    <div @click="onButtonClick('bath')" class="v-button v-button-bath" v-if="!bathButtonActive">
        <div class="vibrating-waves vibrating-waves-white white-1 play"></div>
        <svg class="center" viewBox="0 0 26 26" width="26" height="26">
            <circle class="circle c-white" cx="13" cy="13" r="13"/>
            <circle class="inner-circle c-blue" cx="13" cy="13" r="13"/>
        </svg>
    </div>
    <div @click="onButtonClick('pipe')" class="v-button v-button-pipe" v-if="!pipeButtonActive">
        <div class="vibrating-waves vibrating-waves-blue play"></div>
        <svg class="center" viewBox="0 0 26 26" width="26" height="26">
            <circle class="circle c-blue" cx="13" cy="13" r="13"/>
            <circle class="inner-circle c-white" cx="13" cy="13" r="13"/>
        </svg>
    </div>
    <div @click="onButtonClick('cistern')" class="v-button v-button-cistern" v-if="!cisternButtonActive">
        <div class="vibrating-waves vibrating-waves-white white-2 play"></div>
        <svg class="center" viewBox="0 0 26 26" width="26" height="26">
            <circle class="circle c-white" cx="13" cy="13" r="13"/>
            <circle class="inner-circle c-blue" cx="13" cy="13" r="13"/>
        </svg>
    </div>
    <div @click="onButtonClick('furniture')" class="v-button v-button-furniture" v-if="!furnitureButtonActive">
        <div class="vibrating-waves vibrating-waves-white white-3 play"></div>
        <svg class="center" viewBox="0 0 26 26" width="26" height="26">
            <circle class="circle c-white" cx="13" cy="13" r="13"/>
            <circle class="inner-circle c-blue" cx="13" cy="13" r="13"/>
        </svg>
    </div>
    <div @click="onButtonClick('mirror')" class="v-button v-button-mirror" v-if="!mirrorButtonActive">
        <div class="vibrating-waves vibrating-waves-white white-4 play"></div>
        <svg class="center" viewBox="0 0 26 26" width="26" height="26">
            <circle class="circle c-white" cx="13" cy="13" r="13"/>
            <circle class="inner-circle c-blue" cx="13" cy="13" r="13"/>
        </svg>
    </div>
</div>
<div class="popups-wrapper">
    <div v-click-away="onClickAway" class="popup popup-bath" v-if="bathButtonActive">
        <span class="offer-title">Tuš kabine</span>
        <router-link to="/proizvodi?kategorija=Tuš kabine" class="look-offer me-2">
          Pogledajte ponudu
          <svg class="right-long-arrow"></svg>
        </router-link>
    </div>
    <div v-click-away="onClickAway" class="popup popup-pipe" v-if="pipeButtonActive">
        <span class="offer-title">Instalacije</span>
        <router-link to="/proizvodi?kategorija=Instalacije" class="look-offer me-2">
          Pogledajte ponudu
          <svg class="right-long-arrow"></svg>
        </router-link>
    </div>
    <div v-click-away="onClickAway" class="popup popup-cistern" v-if="cisternButtonActive">
        <span class="offer-title">Sanitarija</span>
        <router-link to="/proizvodi?kategorija=Sanitarija" class="look-offer me-2">
          Pogledajte ponudu
          <svg class="right-long-arrow"></svg>
        </router-link>
    </div>
    <div v-click-away="onClickAway" class="popup popup-furniture" v-if="furnitureButtonActive">
        <span class="offer-title">Kupatilski namestaj</span>
        <router-link to="/proizvodi?kategorija=Kupatilski nameštaj" class="look-offer me-2">
          Pogledajte ponudu
          <svg class="right-long-arrow"></svg>
        </router-link>
    </div>
    <div v-click-away="onClickAway" class="popup popup-mirror" v-if="mirrorButtonActive">
        <span class="offer-title">Ogledala</span>
        <router-link to="/proizvodi?kategorija=Ogledala" class="look-offer me-2">
          Pogledajte ponudu
          <svg class="right-long-arrow"></svg>
        </router-link>
    </div>
</div>
</template>

<script>
import { ref } from "vue";
export default {
    setup(){
        const bathButtonActive = ref(false);
        const pipeButtonActive = ref(false);
        const cisternButtonActive = ref(false);
        const furnitureButtonActive = ref(false);
        const mirrorButtonActive = ref(false);

        const onButtonClick = (buttonName) => {
            makeAllButtonsInactive();
            if(buttonName == "bath")
                bathButtonActive.value = true;
            else if(buttonName == "pipe")
                pipeButtonActive.value = true;
            else if(buttonName == "cistern")
                cisternButtonActive.value = true;
            else if(buttonName == "furniture")
                furnitureButtonActive.value = true;
            else if(buttonName == "mirror")
                mirrorButtonActive.value = true;
        };

        const onClickAway = () => {
            bathButtonActive.value = false;
            pipeButtonActive.value = false;
            cisternButtonActive.value = false;
            furnitureButtonActive.value = false;
            mirrorButtonActive.value = false;
        };

        const makeAllButtonsInactive = () => {
            bathButtonActive.value = false;
            pipeButtonActive.value = false;
            cisternButtonActive.value = false;
            furnitureButtonActive.value = false;
            mirrorButtonActive.value = false;
        }

        return{
            bathButtonActive,
            pipeButtonActive,
            cisternButtonActive,
            furnitureButtonActive,
            mirrorButtonActive,
            makeAllButtonsInactive,
            onButtonClick,
            onClickAway
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/_homepage.scss";

.vibrating-buttons{
  position: relative;
  width: 100%;
  height: 100%;

  @include media("<tablet-large"){
    display: none;
  }
}

.v-button{
  
  position: absolute;
  opacity: 0%;
  animation: reveal-buttons 2s ease-out forwards;
  animation-delay: 0.5s;

  @keyframes reveal-buttons {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.v-button-bath{
  top: 388px;
  right: 593px;

  @include media("<desktop-small"){
    top: 293px;
    right: 448px;
  }
}

.v-button-pipe{
  top: 419px;
  right: 320px;

  @include media("<desktop-small"){
    top: 318px;
    right: 245px;
  }
}

.v-button-cistern{
  top: 352px;
  right: 78px;

  @include media("<desktop-small"){
    top: 266px;
    right: 56px;
  }
}

.v-button-furniture{
  top: 300px;
  right: 221px;

  @include media("<desktop-small"){
    top: 224px;
    right: 160px;
  }
}

.v-button-mirror{
  top: 189px;
  right: 406px;

  @include media("<desktop-small"){
    top: 136px;
    right: 304px;
  }
}

  .circle {
    clip-path: circle(100%);

    @include media("<desktop-small"){
      clip-path: circle(50%);
    }
  }

  .inner-circle{
    clip-path: circle(25%);

    @include media("<desktop-small"){
      clip-path: circle(25%);
    }
  }

  .c-blue{
    fill: color(blue);
  }

  .c-white{
    fill: white;
  }

    .center{
      position: absolute;
      top: 12px;
      left: 12px;
    }

.vibrating-waves {
  animation-duration: 1500ms;
  animation-timing-function: steps(45);
  animation-iteration-count: infinite;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
}

.play {
  @keyframes play45 {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: -2250px 0px;
    }
  }
  animation-name: play45;
}

.vibrating-waves-blue{
  @include background-svg($vibrating-waves-blue);
}

.vibrating-waves-white{
  @include background-svg($vibrating-waves-white);
}

.white-1{
  animation-delay: 0.3s;
}

.white-2{
  animation-delay: 0.6s;
}

.white-3{
  animation-delay: 0.9s;
}

.white-4{
  animation-delay: 1.2s;
}

.popups-wrapper{
  position: relative;
  width: 100%;
  height: 100%;

  @include media("<tablet"){
    display: none;
  }
}

    .popup{
        @include shadow;
        background-color: white;
        width: 250px;
        padding: 15px 28px;
        display: flex;
        flex-direction: column;

        @include media("<desktop-small"){
          width: 250px;
        }
    }

        .offer-title{
            color: color(blue);
            font-weight: bold;
            margin-bottom: 5px;
        }

        .link{
          cursor: pointer;
        }

          .look-offer {
              text-decoration: none;
              color: color(darkgrey);
          }

          .right-long-arrow {
              @include icon;
              @include background-svg($right-long-arrow);
              height: 10px;
              width: 18px;
          }

    .popup-bath{
        position: absolute;
        top: 400px;
        right: 450px;

        @include media("<desktop-small"){
          top: 308px;
          right: 303px;
        }
    }

    .popup-pipe{
        position: absolute;
        top: 416px;
        right: 180px;

        @include media("<desktop-small"){
          top: 314px;
          right: 102px;
        }
    }

    .popup-cistern{
        position: absolute;
        top: 361px;
        right: 20px;

        @include media("<desktop-small"){
          width: 240px;
          top: 275px;
          right: 8px;
        }
    }

    .popup-furniture{
        position: absolute;
        top: 310px;
        right: 140px;

        @include media("<desktop-small"){
          top: 187px;
          right: 67px;
        }
    }

    .popup-mirror{
        position: absolute;
        top: 190px;
        right: 250px;

        @include media("<desktop-small"){
          top: 117px;
          right: 130px
        }
    }

</style>