<template>
  <div class="expolore">
    <h4>Istraži</h4>
    <div class="footer-info-container">
      <span @click="navigateToAboutUsPage()">O nama</span>
    </div>
    <div class="footer-info-container">
      <span @click="navigateToContactPage()">Kontakt</span>
    </div>
    <div class="footer-info-container">
      <span @click="navigateToWishListPage()">Lista želja</span>
    </div>
  </div>
</template>

<script>

import { useRouter } from "vue-router";

export default {
  setup() {

    const router = useRouter();

    const navigateToAboutUsPage = () => {
      router.push('o-nama');
    }

    const navigateToContactPage = () => {
      router.push('kontakt');
    }

    const navigateToWishListPage = () => {
      router.push('lista-zelja');
    }

    return{
      navigateToAboutUsPage,
      navigateToContactPage,
      navigateToWishListPage
    }
    
  },
}
</script>


<style scoped lang="scss">
.footer-info-container {
  margin-bottom: 0px;
  margin-top: 8px;
  font-size: 14px;

  span{
    cursor: pointer;
  }
}

h4, .footer-info-container {
  color: white;
}
</style>
