<template>
  <div v-if="searchLoading" class="loading-spinner d-flex align-items-center justify-content-center">
    <LoadingSpinner :size="25"/>
  </div>
  <div v-click-away="onClickAway" class="search-window-container" v-if="searchedProducts.length > 0">
    <div v-if="!searchLoading" class="search-window">
      <div 
        class="search-product d-flex flex-row" 
        v-for="product in searchedProducts"
        :key="product.id"
        @click="redirectToProductDetails(product.id)"
      >
        <div class="search-product-image-container">
          <img :src="baseUrl + '/' + product.imageUrl" :alt="product.name + ' ' + product.description" />
        </div>
        <div class="product-info">
          <h6 class="product-name">{{ product.name }}</h6>
          <p class="product-price">{{ product.price }},00 RSD</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue'
import axios from 'axios';
export default {
  props: {
    searchString: String,
  },
  components: { LoadingSpinner },
  setup(props) {
    const router = useRouter();
    const baseUrl = process.env.VUE_APP_API_URL;
    const searchedProducts = ref([]);
    const searchLoading = ref(false);
    //use to abort request
    let cancelTokenSource = axios.CancelToken.source();

    watch(() => props.searchString, () => {
        searchProducts();
    });

    const searchProducts = () => {
      const searchString = props.searchString;
      if (searchString) {
        if (searchLoading.value) {
          //abort last request
          cancelTokenSource.cancel();
          //set token for new request
          cancelTokenSource = axios.CancelToken.source();
        }
        searchLoading.value = true;
        axios
          .get(`${baseUrl}/api/Products?PageSize=10&Search=${searchString}`, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            searchedProducts.value = res.data.data;
            searchLoading.value = false;
          })
          .catch(() => {
            //handle errors
          });
      } else {
        searchLoading.value = '';
        onClickAway();
      }
    };

    const onClickAway = () => {
      searchedProducts.value = [];
    }

    const redirectToProductDetails = (productId) => {
      onClickAway();
      router.push(`/proizvodi/${productId}`);
    }

    return {
      searchedProducts,
      searchLoading,
      baseUrl,
      onClickAway,
      redirectToProductDetails 
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/_search-window.scss";

.loading-spinner{
  position: absolute;
  top: 5px;
  right: 7px;
}

.search-window-container {
  position: absolute;
  top: 40px;
  left: -40px;
  z-index: 999;
  width: 383px;
  height: 572px;
  padding: 40px 6px 0px 6px;
  @include background-svg($search-window);
  background-repeat: no-repeat;
}

  .search-window{
    overflow-y: auto;
    height: calc(100% - 11px);
    @include scrollbar;
  }

    .search-product {
      cursor: pointer;
      width: 100%;
      height: 126px;
      padding: 13px 15px;
      border-bottom: 1px solid color(lightgrey);

      &:hover{
        background-color: rgba(252,252,252, 1);
      }
    }

      .search-product-image-container img {
        width: 99px;
        height: 99px;
        margin-right: 10px;
      }

      .product-info {
        height: 100%;
        width: 100%;
        position: relative;
      }

      .product-name {
        padding: 0;
        font-weight: bold;
        color: color(blue);
        font-size: $base-font-size;
        margin: 0;
      }

      .product-price {
        padding: 0;
        color: color(darkgrey);
        font-size: $base-font-size;
        position: absolute;
        bottom: 0px;
        margin: 0;
      }

      

</style>
