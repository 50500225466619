<template>
    <section>
        <h2>Zašto kupovati kod nas?</h2>
        <div class="why-us-wrapper d-flex flex-row">
            <div class="image-wrapper">
                <img src="@/assets/images/whyus.png" alt="Veliki izbor i povoljne cene.">
            </div>
            <div class="text-wrapper">
                <p>Naš odgovor je jednostavan, nemojte pitati nas, 
                    pitajte naše klijente. Možemo vam reći dva glavna razloga zašto su naši kupci odlučili da 
                    kupuju kod nas: <span>veliki izbor i povoljne cene.</span> Ako vam je prioritet dobar posao, izuzetna usluga, 
                    kvalitetan materijal i dugotrajna veza, onda smo mi izbor za vas.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style lang="scss" scoped>

section {
    padding: 0 $grid-margin-width;
    margin-right: 0px;
}

    h2{
        @include noselect;
    }

    .why-us-wrapper{ 
        margin: 20px 0;
        @include media("<tablet-large"){
            flex-direction: column !important;
        }
    }

        .image-wrapper{
            @include media("<tablet-large"){
                text-align: center;
            }
        }

            .image-wrapper img {
                width: 30vw;
                object-fit: cover;
                @include media("<tablet-large"){
                    width: 80%;
                }
                @include media("<phone-large"){
                    width: 100%;
                }
            }

        .text-wrapper {
            display: flex;
            align-items: center;
            @include media("<tablet-large"){
                width: 100%;
            }
        }

        .text-wrapper p {
            color: color(blue);
            font-size: 1rem;
            padding: 0;
            margin: 0;
            text-align: justify;
            @include media("<tablet") {
                margin-bottom: 20px;
            }
        }

        .text-wrapper p span {
            font-weight: bold;
        }

</style>