<template>
  <aside class="side-drawer" :class="{'slide-open-side-drawer': isSideDrawerOpen}">
    <slot></slot>
  </aside>
</template>

<script>
export default {
  props:{
    isSideDrawerOpen: Boolean
  },
  setup() {
    
  },
}
</script>

<style scoped lang="scss">
.side-drawer {
  position: fixed;
  right: 0;
  top: 70px;
  z-index: 100;
  height: calc(100% - 70px);
  width: 40%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow-y: auto;
  padding-left: 30px;
  padding-top: 20px;
  transform-origin: center right;
  transition: transform 0.2s;
  transform: scale(0, 1);

  @include media("<tablet"){
    width: 60%;
  }

  @include media("<phone-medium"){
    width: 85%;
  }

}

.side-drawer.slide-open-side-drawer{
  transform: scale(1, 1);
}

</style>
