<template>
    <div 
      className="lds-dual-ring" 
      :style="{
        width : spinnerSize + 'px',
        height: spinnerSize + 'px',
        border: spinnerSize/7 + 'px solid ' + colors.blue,
        borderColor: colors.blue + ' transparent ' + colors.blue + ' transparent',
        borderRadius: spinnerSize/2 + 'px'
      }">
    </div>
</template>

<script>
import colors from "@/assets/scss/globals/_colors.scss";
import {ref} from "vue";

export default {
  props: {
    size: Number,
  },
  setup(props) {

    const spinnerSize = ref(props.size);
    if(spinnerSize.value == undefined){
      spinnerSize.value = 40;
    }

    return{
      spinnerSize,
      colors
    }
  },
}
</script>


<style lang="scss" scoped>
.lds-dual-ring {
  display: block;
  margin: 1px;
  border-color: color(blue) transparent color(blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>