export const SETTINGS = {
  itemsToShow: 5,
  snapAlign: "start",
  wrapAround: true
};

export const BREAKPOINTS = {
  1520: {
    itemsToShow: 5,
  },
  1200: {
    itemsToShow: 4,
  },
  992: {
    itemsToShow: 3,
  },
  768: {
    itemsToShow: 2.8,
  },
  580: {
    itemsToShow: 2.3,
  },
  411: {
    itemsToShow: 1.7,
  },
  350: {
    itemsToShow: 1.5,
  },
  320: {
    itemsToShow: 1.3,
  },
  260: {
    itemsToShow: 1.2,
  },
  0: {
    itemsToShow: 1.1,
  }
};
