<template>
  <div class="specifications-container">
    <h4>Detalji</h4>
    <div class="specifications">
      <hr>
      <div
        class="d-flex justify-content-between"
        v-for="(characteristicValue, characteristicName) in characteristics"
        :key="characteristicName"
      >
        <p>{{ characteristicName }}</p>
        <p>{{ characteristicValue }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    characteristics: Object,
  },
};
</script>

<style scoped lang="scss">

.specifications-container {
  margin-top: 30px;
}

  hr{
    background-color: color(lightgrey);
    margin: 10px 0;
    width: 100%;
  }

  h4{
    color: color(darkgrey);
    margin-bottom: 6px;
  }

p {
  color: color(darkgrey);
  font-size: $base-font-size;
  margin-bottom: 8px;
}

</style>
