const minLength = (value, num) => {
    return (value.length + 1) >= num;
}

const isEmail = (value) => {
    return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const isNumber = (value) => {
    return value.length > 0 && !isNaN(value);
}

export default (value, validator) => {
    if(validator.type == "MIN_LENGTH") {
        return minLength(value, validator.num);
    }
    else if(validator.type == "EMAIL") {
        return isEmail(value);
    }
    else if(validator.type == "NUMBER") {
        return isNumber(value);
    }
}