<template>
    <div>
        <img src="@/assets/images/404.png" alt="Nista nije pronadjeno" class="image-404 desktop-404-image">
        <img src="@/assets/images/404-tablet.png" alt="Nista nije pronadjeno" class="image-404 tablet-404-image">
        <img src="@/assets/images/404-phone.png" alt="Nista nije pronadjeno" class="image-404 phone-404-image">
        <div class="content-wrapper">
            <h1>Ups!</h1>
            <h4>Stranica koju tražite je izbrisana ili pomerena.</h4>
            <div class="d-flex find-wrapper">
                <span class="grey-text">Možda možete da pronađete to što tražite ovde?</span>
            </div>
            <div v-if="categories" class="flex-wrapper d-flex flex-row">
                <div 
                v-for="category in categories"
                :key="category.id" 
                class="rows"
                :class="{'odd-row': category.id % 2 == 1}"
                :style="{'flex-basis': category.id % 3 == 2 ? '40%' : '25%'}"
                @click="navigateToCategory(category.name)"
                >
                    <div class="category" :style="{ 'background-color': hexColors[category.id-1]}">
                        {{category.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
    setup(){
        const store = useStore();
        const router = useRouter();

        const categories = computed(() => {
            return store.getters.getCategories;
        });

        const hexColors = ref([]);
        hexColors.value.push("#E7EEFF");
        hexColors.value.push("#E7FFF8");
        hexColors.value.push("#F2EAB7");
        hexColors.value.push("#F3D7DA");
        hexColors.value.push("#E4CFFC");
        hexColors.value.push("#E2E2E2");
        hexColors.value.push("#F8D2AD");
        hexColors.value.push("#E3F1CD");
        hexColors.value.push("#F1D8F8");

        const navigateToCategory = (categoryName) => {
            router.push(`/proizvodi?kategorija=${categoryName}`);
        }

        return {
            categories,
            hexColors,
            navigateToCategory
        }

    }
}
</script>

<style lang="scss" scoped>

.image-404{
    position: absolute;
    z-index: -1;
    object-fit: cover;
    width: 100%;
}

.desktop-404-image{
    height: 82vh;
    display: none;
    @include media("<tablet-large"){
        display: none;
    }
}

.tablet-404-image{
    height: 90vh;
    display: none;
    @include media(">=tablet-large"){
        display: none;
    }

    @include media("<tablet"){
        display: none;
    }
}

.phone-404-image{
    top: 20px;
    height: 70vh;
    display: none;
    @include media(">=tablet"){
        display: none;
    }
}

.content-wrapper{
    padding: 73px $grid-margin-width 140px $grid-margin-width;    
    background-image: url('~@/assets/images/404.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    @include media("<tablet-large"){
        background-image: url('~@/assets/images/404-tablet.png');
        padding-bottom: 100px;
    }

    @include media("<tablet"){
        background-image: url('~@/assets/images/404-phone.png');
        background-position: center top;
        padding-top: 190px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @include media("<phone-small"){
        padding-top: 270px;
    }
}

    h1{
        margin-bottom: 5px !important;
        @include media("<tablet"){
            margin-bottom: 10px !important;
        }
    }

    h4{
        margin-bottom: 30px !important;
        @include media("<tablet"){
            text-align: center;
        }
    }

    .find-wrapper{
        margin-bottom: 20px;
    }

        .grey-text{
            color: color(darkgrey);
        }

    .flex-wrapper{
        flex-wrap: wrap;
        width: 60vw;
        @include media("<tablet-large"){
            width: 65vw;
        }

        @include media("<tablet"){
            width: 100%;
        }
    }

    .rows{
        margin-bottom: 20px;

        @include media("<tablet-large"){
            flex-basis: 45% !important;
        }

        @include media("<tablet"){
            flex-basis: 48% !important;
        }

        @include media("<phone-small"){
            flex-basis: 99% !important;
            text-align: center;
        }
    }

    .odd-row{ 
        @include media("<tablet-large"){
            flex-basis: 55% !important;
        }

        @include media("<tablet"){
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;
            text-align: end;
            flex-basis: 48% !important;
        }

        @include media("<phone-small"){
            justify-content: center;
            flex-basis: 98% !important;
            text-align: center;
            margin-right: 0;
        }
    }

        .category{
            cursor: pointer;
            height: 100%;
            align-items: center;
            display: inline-flex;
            color: color(blue);
            margin-right: 10px;
            font-weight: bold;
            padding: 5px 15px;

            @include media("<tablet"){
                margin: 0;
            }

            @include media("<phone-small"){
                display: block;
                width: 100%;;
            }
        }

</style>