<template>
  <div class="address">
    <h4>Adresa</h4>
    <address>
      <div class="footer-info-container">
        <span>70 Vladimira Rolovića</span>
      </div>
      <div class="footer-info-container">
        <span>34000, Kragujevac</span>
      </div>
      <div class="footer-info-container">
        <span>Šumadijski okrug</span>
      </div>
      <div class="footer-info-container d-flex align-items-center">
        <span style="cursor:pointer" @click="navigateToContactPage()" class="fw-bold">Pogledajte na mapi</span>
        <svg @click="navigateToContactPage()" class="right-long-arrow-white ms-1"></svg>
      </div>
    </address>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  
  setup() {
    const router = useRouter();

    const navigateToContactPage = () => {
      router.push('kontakt');
    }

    return {
      navigateToContactPage
    }
  },
}
</script>


<style scoped lang="scss">

.address {
  margin-top: 32px;
}

  .footer-info-container {
    margin-bottom: 0px;
    margin-top: 8px;
    font-size: 14px;
  }

    .right-long-arrow-white{
      height: 10px;
      width: 18px;
      @include background-svg($right-long-arrow-white);
      @include icon;
    }

h4, .footer-info-container {
  color: white;
}


</style>
