<template>
    <div class="d-flex wrapper">
        <div class="story">
            <h2>Mi pomažemo klijentima da kreiraju svoje kupatilo iz snova</h2>
            <span>
                Sa skoro 30 godina iskustva u privredi, razumemo koliko su kupatila važna za komfor i udobnost kod kuće i na poslu. Od osnivanja kompanije 1995. godine naša misija i vrednosti su ostale iste - pomaganje klijentima da kreiraju svoje kupatilo iz snova.
            </span>
            <span>
                Zbog toga, pažljivo biramo sa kim sarađujemo - 
                dobavljačima čiji su proizvodi u skladu sa brendom 
                Vodoterm, visokog kvaliteta i odlične vrednosti za novac. 
                Skladištimo samo proizvode koje imaju testirane materijale i 
                nepobediv kvalitet jer želimo da klijentima kupatilo iz snova 
                služi celog života.
            </span>
            <span>
                Mi nudimo širok spektar proizvoda, sve od vodovodne i kanalizacione opreme do najsitnijih galanterijskih predmeta koji će dati život Vašem novom kupatilu. Možete nas posetiti u Kragujevcu, u ulici Vladimira Rolovića br. 70 ili da nas kontaktirate putem mobilnog telefona za bilo kakva pitanja. Naši zaposleni su dostupni da Vam asistiraju pri izboru proizvoda, nudeći Vam najbolje opcije i cene. Pa kako želite da Vaše kupatilo iz snova izgleda?
            </span>
        </div>
        <img class="image-desktop" src="@/assets/images/about-us.jpg" alt="O nama - Istorijat Vodoterm DOO, Kragujevac">
        <img class="image-mobile" src="@/assets/images/about-us.jpg" alt="O nama - Istorijat Vodoterm DOO, Kragujevac">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.wrapper{
    position: relative;
    @include media("<tablet"){
        flex-direction: column !important;
    }
}

    h2{
        margin-bottom: 30px !important;
    }

    .story{
        position: absolute;
        top: 90px;
        width: 60vw;
        left: $grid-margin-width;

        @include media("<tablet"){
            position: initial !important;
            order: 2;
            width: 100%;
            padding: 30px $grid-margin-width;
        }
    }

        span{
            width: 39vw;
            display: inline-block;
            margin-bottom: 30px;
            color: color(blue);
            text-align: justify;

            @include media("<tablet"){
                width: 100%;
            }
        }

    .image-desktop{
        margin-left: auto;
        object-fit: cover;
        width: 50vw;
        height: 1000px;
        
        @include media("<tablet"){
            display: none;
        }
    }

    .image-mobile{
        object-fit: cover;
        height: 550px;
        
        @include media(">=tablet"){
            display: none;
        }

        @include media("<phone-large"){
            height: 400px;
        }

        @include media("<phone-small"){
            height: 300px;
        }
    }
</style>
