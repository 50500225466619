export default {
  setActionProducts(state, payload) {
    state.actionProducts = payload.value;
  },

  setCategories(state, payload) {
    state.categories = payload.value;
  },

  setProductsOfSertainCategory(state, payload) {
    state.products = payload.value;
  },

  deleteProduct(state, payload) {
    const newProducts = state.products.filter(p => p.id !== payload.value);
    console.log(state.products.length, newProducts.length);
    state.products = newProducts;
  },

  setWishlistCount(state, payload) {
    state.wishlistCount = payload.value;
  }
};
