export const SETTINGS = {
  itemsToShow: 7,
  snapAlign: "start",
  wrapAround: true,
};

export const BREAKPOINTS = {
  0: {
    itemsToShow: 1.5,
  },
  320: {
    itemsToShow: 2.4,
  },
  411: {
    itemsToShow: 3.4,
  },
  580: {
    itemsToShow: 4,
  },
  768: {
    itemsToShow: 5,
  },
  992: {
    itemsToShow: 6,
  },
  1200: {
    itemsToShow: 7,
  },
  1520: {
    itemsToShow: 9,
  }
};
