export const IMAGES = [
    { fullResPath: require("@/assets/images/ourwork/1.jpg"), path : require("@/assets/images/ourwork/low/1.jpg"), top: '167px', left: '0px', width: '270px', height: '180px', zindex: 1 },
    { fullResPath: require("@/assets/images/ourwork/2.jpg"), path : require("@/assets/images/ourwork/low/2.jpg"), top: '23px', left: '210px', width: '168px', height: '205px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/3.jpg"), path : require("@/assets/images/ourwork/low/3.jpg"), top: '48px', left: '407px', width: '256px', height: '159px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/4.jpg"), path : require("@/assets/images/ourwork/low/4.jpg"), top: '109px', left: '624px', width: '216px', height: '225px', zindex: 1 },
    { fullResPath: require("@/assets/images/ourwork/5.jpg"), path : require("@/assets/images/ourwork/low/5.jpg"), top: '28px', left: '1057px', width: '322px', height: '193px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/6.jpg"), path : require("@/assets/images/ourwork/low/6.jpg"), top: '120px', left: '886px', width: '266px', height: '197px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/7.jpg"), path : require("@/assets/images/ourwork/low/7.jpg"), top: '167px', left: '1411px', width: '270px', height: '180px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/8.jpg"), path : require("@/assets/images/ourwork/low/8.jpg"), top: '21px', left: '1551px', width: '273px', height: '205px', zindex: 1 },
    { fullResPath: require("@/assets/images/ourwork/9.jpg"), path : require("@/assets/images/ourwork/low/9.jpg"), top: '48px', left: '1850px', width: '256px', height: '159px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/10.jpg"), path : require("@/assets/images/ourwork/low/10.jpg"), top: '150px', left: '2050px', width: '294px', height: '193px', zindex: 1 },
    { fullResPath: require("@/assets/images/ourwork/11.jpg"), path : require("@/assets/images/ourwork/low/11.jpg"), top: '28px', left: '2216px', width: '322px', height: '193px', zindex: 1 },
    { fullResPath: require("@/assets/images/ourwork/12.jpg"), path : require("@/assets/images/ourwork/low/12.jpg"), top: '83px', left: '2507px', width: '177px', height: '266px', zindex: 2 },
    { fullResPath: require("@/assets/images/ourwork/13.jpg"), path : require("@/assets/images/ourwork/low/13.jpg"), top: '167px', left: '2781px', width: '270px', height: '180px', zindex: 1 },
]