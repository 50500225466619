<template>
    <BaseBackdrop @click="closeMobileSearchWindow" :topPosition="'70'" class="backdrop" :class="{'backdrop-visible': isVisible}"/>
    <div class="mobile-search-window" :class="{'slide-open-mobile-search-window': isVisible}">
        <div class="search-container d-flex justify-content-between">
            <input 
                type="text" 
                class="search-input" 
                placeholder="Pretraži proizvode" 
                :value="searchValue"
                @input="onSearch"
            >
             <svg @click="redirectToProductsPage" class="search-icon" alt="Pretraži"></svg>
        </div>
        <div v-if="searchLoading" class="d-flex align-items-center justify-content-center" style="width: 100%; height: 200px;" >
            <LoadingSpinner />
        </div>
        <template v-else>
            <div
                class="search-product"
                v-for="product in searchedProducts"
                :key="product.id"
                @click="redirectToProductDetails(product.id)"
            >
                <div
                class="
                    search-product-image-container
                    d-flex
                    justify-content-center
                    align-items-center"
                >
                    <img :src="baseUrl + '/' + product.imageUrl" alt="" />
                </div>
                <div class="product-info">
                    <h6 class="product-name">{{ product.name }}</h6>
                    <p class="product-price">{{ product.price }},00 RSD</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from "vue-router";
import LoadingSpinner from '../../shared/LoadingSpinner.vue'
import BaseBackdrop from "@/components/shared/BaseBackdrop.vue";
export default {
    components: { BaseBackdrop, LoadingSpinner},
    emits: ["closed"],
    props: {
        isVisible: Boolean
    },
    setup(props, context) {
        const router = useRouter();
        const baseUrl = process.env.VUE_APP_API_URL;
        const searchValue = ref('');
        const searchLoading = ref(false);
        const searchedProducts = ref([]);
        //use to abort request
        let cancelTokenSource = axios.CancelToken.source();

        const closeMobileSearchWindow = () => {
            searchedProducts.value = [];
            context.emit('closed');
        }

        const searchProducts = () => {
            if(searchValue.value) {
                if (searchLoading.value) {
                    //abort last request
                    cancelTokenSource.cancel();
                    //set token for new request
                    cancelTokenSource = axios.CancelToken.source();
                }
                searchLoading.value = true;
                axios
                .get(`${baseUrl}/api/Products?PageSize=10&Search=${searchValue.value}`, {
                    cancelToken: cancelTokenSource.token,
                })
                .then((res) => {
                    searchedProducts.value = res.data.data;
                    searchLoading.value = false;
                })
                .catch(() => {
                    //handle errors
                });
            } else {
                searchedProducts.value = [];
            }
        }

        const onSearch = (e) => {
            const searchString = e.target.value;
            searchValue.value = searchString;
            searchProducts();
        }

        const redirectToProductDetails = (productId) => {
            closeMobileSearchWindow();
            router.push(`/proizvodi/${productId}`);
        }

        const redirectToProductsPage = () => {
            if(searchValue.value) {
                const teampSearchValue = searchValue.value;
                searchValue.value = '';
                closeMobileSearchWindow();
                router.push(`/proizvodi?search=${teampSearchValue}`);
            }
        }

        return {
            closeMobileSearchWindow,
            onSearch,
            searchLoading,
            baseUrl,
            searchValue,
            searchedProducts,
            redirectToProductDetails,
            redirectToProductsPage 
        }
    },
}
</script>

<style scoped lang="scss">

.backdrop{
    display: none;
}

.backdrop.backdrop-visible{
    display: block;
}

.mobile-search-window {
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
    overflow-y: auto;
    width: 340px;
    background: white;
    transform-origin: center right;
    transition: transform 0.2s;
    transform: scale(0, 1);

    @include media("<phone-medium"){
        width: 85%;
    }
}

.mobile-search-window.slide-open-mobile-search-window{
    transform: scale(1, 1);
}

    .search-container {
        padding: 15px $grid-margin-width 15px 26px;
        border-bottom: 1px solid color(lightgrey);
    }

        .search-input {
            width: 100%;
            border: none;
            outline: none;
        }

        .search-icon {
            cursor: pointer;
            min-width: 22px;
            max-width: 22px;
            height: 22px;
            @include icon;
            @include background-svg($search-icon);
        }

    .search-product {
        cursor: pointer;
        width: 100%;
        height: 116px;
        padding: 13.5px 15px;
        display: flex;
        border-bottom: 1px solid color(lightgrey)
    }

        .product-info {
            height: 100%;
            width: 100%;
            padding: 0px;
            position: relative;
        }

        .product-name {
            padding: 0;
            font-weight: bold;
            color: color(blue);
            font-size: $base-font-size;
            margin: 0;
        }

        .product-price {
            padding: 0;
            color: color(darkgrey);
            font-size: $base-font-size;
            position: absolute;
            bottom: 0px;
            margin: 0;
        }

        .search-product-image-container img {
            width: 99px;
            height: 99px;
            margin-right: 10px;
        }
</style>