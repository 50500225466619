<template>
  <div class="business-hours">
    <h4>Radno vreme</h4>
    <div class="business-hours-grid">
      <div>
        <div class="footer-info-container">
          <span>pon-pet:</span>
        </div>
        <div class="footer-info-container">
          <span>sub:</span>
        </div>
        <div class="footer-info-container">
          <span>ned:</span>
        </div>
      </div>
      <div>
        <div class="footer-info-container">
          <span>07:00-19:00</span>
        </div>
        <div class="footer-info-container">
          <span>07:00-15:00</span>
        </div>
        <div class="footer-info-container">
          <span>Zatvoreno</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.business-hours {
  margin-top: 32px;

  @include media("<tablet") {
      column-gap: 24px;
  }
}

  .business-hours-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 31px;

    @include media(">=phone-large") {
      width: 270px;
      column-gap: 24px;
    }

    @include media("<phone-large") {
      column-gap: 5px;
    }

    @include media("<phone-small") {
      column-gap: 5px;
    }
  }

    .footer-info-container {
      margin-bottom: 0px;
      margin-top: 8px;
      font-size: 14px;
    }

    h4, .footer-info-container {
      color: white;
    }

</style>
