<template>
    <div>
        <div class="product-wrapper d-flex" v-on:click="navigateToProductDetailsPage()">
            <img class="product-image" :src="baseUrl + '/' + product.imageUrl" :alt="product.name + ' ' + product.description" />
            <div class="product-info d-flex flex-column">
                <span class="product-name">{{product.name}}</span>
                <span class="category-name">{{product.categoryName}}</span>
                <span class="old-price" v-if="product.actionPrice">{{ product.price}},00
                    <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
                    <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
                </span>
                <span class="action-price" v-if="product.actionPrice">{{ product.actionPrice}},00
                    <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
                    <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
                </span>
                <span class="regular-price" v-if="!product.actionPrice">{{ product.price}},00
                    <span v-if="product.categoryName.toLowerCase().includes('pločice')"> RSD/m&#178;</span>
                    <span v-if="!product.categoryName.toLowerCase().includes('pločice')"> RSD</span>
                </span>
                <base-button class="btn" :buttonText="'Sačuvaj'" @click.stop="addToWishList"></base-button>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';
import { inject } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
export default {
    components: { BaseButton },
    props: {
        product: Object
    },
    setup(props){
        const router = useRouter();
        const store = useStore();
        const baseUrl = process.env.VUE_APP_API_URL;

        const toast = inject('toast');

        const navigateToProductDetailsPage = () => {
            router.push(`/proizvodi/${props.product.id}`);
        }

        const addToWishList = () => {
            let wishList = getWishList();
            if (!wishList) wishList = {};
            let count = 1;
            if (!wishList[props.product.id]) {
                wishList[props.product.id] = {};
            } else {
                count = wishList[props.product.id].count + 1;
            }

            wishList[props.product.id].count = count;
            wishList[props.product.id].name = props.product.name;
            wishList[props.product.id].categoryName = props.product.categoryName;
            wishList[props.product.id].price = props.product.actionPrice ? props.product.actionPrice : props.product.price;
            wishList[props.product.id].imageUrl = process.env.VUE_APP_API_URL + "/" + props.product.imageUrl;

            if(props.product.categoryName.toLowerCase().includes('pločice')){
                wishList[props.product.id].squaresInBox = props.product.characteristics['Kvadrata u kutiji'];
            }

            localStorage.setItem("vodotermWishList", JSON.stringify(wishList));
            store.dispatch("setWishlistCount");
            toast.success("Proizvod je dodat u listu želja");
        }

        const getWishList = () => {
            let parsedData = {};
            try {
                parsedData = JSON.parse(localStorage.getItem("vodotermWishList"));
            } catch (error) {
                // handle error
            }
            return parsedData;
        };

        return {
            baseUrl,
            navigateToProductDetailsPage,
            addToWishList
        }
    }
}
</script>

<style lang="scss" scoped>

.product-wrapper{
    padding-top: 30px;
    padding-bottom: 22px;
    width: 100%;
}

    .product-image{
        width: 100px;
        height: 100px;
        object-fit: contain;

        @include media("<phone-medium"){
            width: 77px;
            height: 77px;
        }
    }

    .product-info{
        width: 100%;
        margin-left: 20px;
    }

        .product-name{
            font-size: $h4-size;
            font-weight: bold;
            color: color(blue);
            margin-bottom: 5px;

            @include media("<phone-medium"){
                font-size: 16px;
            }
        }

        .category-name{
            color: color(darkgrey);
            font-size: 16px;
            margin-bottom: 10px;

            @include media("<phone-small"){
                font-size: 14px;
            }
        }

        .action-price {
            color: color(red);
            font-weight: $light-font-weight !important;
        }

        .old-price {
            text-decoration: line-through;
            color: color(darkgrey);
            font-size: 16px;
            margin-bottom: 5px;

            @include media("<phone-small"){
                font-size: 14px;
            }
        }

        .regular-price {
            color: color(darkgrey);
            font-weight: $light-font-weight !important;
        }

        .btn{
            width: 100%;
            margin-top: 10px;
        }

hr{
    background-color: color(darkgrey);
    margin-left: -$grid-margin-width;
    margin-right: -$grid-margin-width;
}

</style>