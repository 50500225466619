<template>
  <div ref="root" class="map-root"></div>
</template>

<script>
import {onMounted, ref, watch} from 'vue';
import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import * as interactions from 'ol/interaction';
import {Icon, Style} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import OSM from 'ol/source/OSM';
import 'ol/ol.css';

export default {
    props: {
        shiftPressed : Boolean
    },
    setup(props){
        const root = ref(null);
        const iconFeature = new Feature({
            geometry: new Point([20.90142721051242, 44.03343202510874]),
            name: 'Vodoterm',
            
        });
        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.3, 30],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: require("@/assets/icons/place.png")
            }),
        });

        iconFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
            features: [iconFeature],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });
        
        const mouseWheelZoomInteraction = new MouseWheelZoom();
        mouseWheelZoomInteraction.setActive(false);

        watch(() => props.shiftPressed, () => {
            mouseWheelZoomInteraction.setActive(props.shiftPressed);
        });

        onMounted(() => {
            new Map({
                target: root.value,
                interactions: [
                    mouseWheelZoomInteraction,
                    new interactions.DragRotate(),
                    new interactions.DragPan(),
                    new interactions.DoubleClickZoom(),
                    new interactions.PinchZoom(),
                    new interactions.PinchRotate()
                ],
                layers: [
                    new TileLayer({
                        source: new OSM()
                    }),
                    vectorLayer
                ],
                view: new View({
                    zoom: 14,
                    projection: 'EPSG:4326',
                    center: [20.90142721051242, 44.03343202510874],
                    constrainResolution: true
                }),
            })
        });

      return{
          root,
      }
    }
}
</script>

<style lang="scss" scoped>
    .map-root{
        width: 100%;
        height: 100%
    }
</style>