export const IMAGES = [
    require("@/assets/icons/1.png"),
    require("@/assets/icons/2.png"),
    require("@/assets/icons/3.png"),
    require("@/assets/icons/4.png"),
    require("@/assets/icons/5.png"),
    require("@/assets/icons/6.png"),
    require("@/assets/icons/7.png"),
    require("@/assets/icons/8.png"),
    require("@/assets/icons/9.png"),
    require("@/assets/icons/10.png"),
    require("@/assets/icons/11.png"),
    require("@/assets/icons/12.png"),
    require("@/assets/icons/13.png")
]