<template>
    <div class="quote-container" :class="(evenOdd == 'odd' && windowWidth <= 768 ) ? 'left-float' : 'right-float'">
        <div style="position: relative;">
            <div class="quote">
                <div class="stars">
                <template v-for="i in [1,2,3,4,5]" :key="i">
                    <svg class="star"></svg>
                </template>
                </div>
                <p class="quote-text">{{quoteText}}
                </p>
            </div>
            <svg class="triangle-icon" :style="[evenOdd == 'odd' && windowWidth < 768 ? {'left': '5%' }: {'right': '5%'}]"></svg>
        </div>
        <p class="username">{{username}}</p>
        <div style="clear: both;"></div>
        <p class="profession">{{profession}}</p>
        <div style="clear: both;"></div>
    </div>
</template>

<script>
import {onMounted, ref, onUnmounted, computed} from 'vue';
export default {
    props: {
        evenOdd: String,
        quoteText: String,
        username: String, 
        profession: String
    },
    setup() {
        const windowWidth = ref(window.innerWidth);

        onMounted(() => {
            window.addEventListener('resize', onResize);
        })

        const onResize = () => {
            windowWidth.value = window.innerWidth;
        }

        onUnmounted(() => {
            window.removeEventListener('resize', onResize);
        })

        const middlePhoneSize = computed(() => {
            return windowWidth.value >= 411 && windowWidth.value <= 768;
        })

        return {
            windowWidth,
            middlePhoneSize,
        }
    },
}
</script>

<style scoped lang="scss">

.quote-container {
    width: calc(100% / 3 - 32px / 3);
    margin-bottom: 23px;

    @include media("<desktop-small") {
      width: calc(100% / 2 - 8px);
    }

    @include media("<tablet", ">=phone-medium") {
      width: 55vw;
      margin-bottom: -35px;
    }

    @include media("<=phone-medium") {
      width: 80.6vw;
      margin-left: 0!important;
    }
}

@include media("<tablet", ">=phone-medium") {
    .right-float {
        margin-left: calc(100% - 6.5% - 55%) !important; // 5% is the $grid-margin-width

        .username, .profession {
            float: right;
        }
    }
}

@include media("<=phone-medium") {
    .right-float {
        .username, .profession {
            float: right;
        }
    }
}

.quote {
    width: 100%;
    background: color(lightgrey);
    padding: 20px 30px;
    margin: 0!important;
    min-height: 165px;
}

    .star {
        width: 24px;
        height: 24px;
        @include background-svg($star-icon);
        margin-left: 6px;
    }

    .star:first-child {
        margin-left: 0px;
    }

    .quote-text {
        color: color(blue);
        font-size: 1rem;
        margin-top: 23px;
        margin-bottom: 0;
        @include media("<tablet") {
            font-size: 0.875rem; 
        }
    }

.triangle-icon {
    width: 23px;
    height: 19px;
    @include background-svg($quote-triangle-icon);
    margin: 0!important;
    position: absolute;
    bottom: -19px;

    @include media(">=tablet") {
      right: 10%;
    }
}

.username {
    color: color(blue);
    font-size: 1rem;
    margin-top: 24px;
    margin-bottom: 0;

    @include media(">=tablet") {
        float: right;
    }
}

.profession {
    color: color(darkgrey);
    font-size: 0.875rem;
    margin-top: 5px;
    margin-bottom: 0px;

    @include media(">=tablet") {
        float: right;
    }
}
</style>