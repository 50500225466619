<template>
    <button type="button" class="btn btn-primary">{{buttonText}}</button>
</template>

<script>
export default {
    props:{
        buttonText: String
    }
}
</script>

<style lang="scss" scoped>

.btn {
    background: white;
    color: color(blue);
    font-weight: $bold-font-weight;
    border-radius: 0 !important;
    border: 1px solid color(blue);
    box-shadow: none !important;

    &:hover{
        background-color: white;
        color: color(lightblue);
    }

    &:focus{
        background-color: white;
        color: color(lightblue);
    }
}

</style>