<template>
  <div class="pagination">
    <svg
      @click="changePage(currentPage - 1)"
      class="arrow-icon"
      style="margin-right: 7.5px"
    ></svg>
    <template v-for="(number, index) in numbers" :key="number">
      <div
        class="dots"
        v-if="
          ((index == 1 && numbers[0] < numbers[1] - 1) ||
          (index == numbers.length-1 && numbers[numbers.length-1] > numbers[numbers.length-2] + 1))
        "
      >
        <span>.</span><span>.</span><span>.</span>
      </div>
      <div
        class="number"
        v-bind:class="number == currentPage ? 'active' : 'not-active'"
        @click="changePage(number)"
      >
        {{ number }}
      </div>
    </template>
    <svg
      @click="changePage(currentPage + 1)"
      class="arrow-icon right-arrow-icon"
      style="margin-left: 7.5px"
    ></svg>
  </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount } from "vue";

export default {
  props: {
    currentPage: Number,
    totalPages: Number,
  },

  emits: ["change"],

  setup(props, context) {
    const debounce = require('lodash.debounce');
    const debounceResize = debounce(() => { onScreenResize() }, 500);
    const width = ref(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
    const numbers = computed(() => {
      if(width.value < 270) {
        return [props.currentPage];
      }
      let subtract = 2;

      if(width.value < 992) {
        subtract = 1;
      }
      let left = props.currentPage - subtract;
      let right = props.currentPage + subtract;

      if(width.value < 370) {
        right = props.currentPage;
      }

      while (left <= 0) {
        left++;
        right++;
      }

      while (right > props.totalPages) {
        right--;
      }

      const newArray = [];

      for (let i = left; i <= right; i++) {
        newArray.push(i);
      }
      
      if(width.value > 370) {
        const len = width.value >= 992 ? 5 : 3;
        while (newArray.length < len && left > 1) {
          left--;
          newArray.unshift(left);
        }
      }

      newArray[0] = 1;
      if(width.value >= 370) {
        newArray[newArray.length - 1] = props.totalPages;
      }

      return newArray;
    });

    const changePage = (number) => {
      if (number >= 1 && number <= props.totalPages) {
        context.emit("change", number);
      }
    };

    onMounted(() => {
      window.addEventListener("resize", 
        debounceResize
      );
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", debounceResize);
      debounceResize.cancel();
    })

    const onScreenResize = () => {
      // When screen resizes breakpoints must change
      getWidth();
    }

    const getWidth = () => {
      width.value = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    }

    return {
      numbers,
      changePage,
      width
    };
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
}

.number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7.5px;
  margin-right: 7.5px;
  font-weight: $bold-font-weight;
  cursor: pointer;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7.5px;
  margin-right: 7.5px;
  color: color(blue);
  text-align: center;

  span {
    font-weight: $bold-font-weight;
  }
}

.active {
  background: color(blue);
  color: white;
}

.not-active {
  background: white;
  color: color(blue);
  border: 2px solid color(blue);
}

.arrow-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  @include background-svg($left-arrow-in-circle);
}

.right-arrow-icon {
  transform: rotate(180deg);
}
</style>
