<template>
    <TheHeader />
    <router-view v-slot="{Component}">
        <transition name="fade">
            <component :is="Component" :key="$route.path"></component>
        </transition>
    </router-view>
    <TheFooter />
</template>

<script>
import TheHeader from "./components/layout/header/TheHeader.vue";
import TheFooter from "./components/layout/footer/TheFooter.vue";
import { useRouter } from "vue-router";
export default {
    components: { TheFooter, TheHeader },
    setup() {
        const router = useRouter();
        router.beforeEach(() => {
            window.scrollTo(0, 0)
        })
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>