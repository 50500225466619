<template>
    <div v-if="product.categoryName.toLowerCase().includes('pločice') && product.characteristics['Kvadrata u kutiji']" class="tiles-calculator">
        <h4>Kalkulator cene</h4>
        <p>Cena kutije: ~{{boxPrice}},00 RSD</p>
        <p>Kvadrata u kutiji: {{product.characteristics['Kvadrata u kutiji']}}</p>
        <div class="d-flex">
            <div class="input-wrapper-first d-flex flex-column w-100">
                <span>Potrebno m&#178;</span>
                <input v-model="numberOfSquares" type="number" min="0" max="20000" @input="onNumberOfSquaresChange" class="form-control">
            </div>
            <div class="input-wrapper-second d-flex flex-column w-100">
                <span>Ukupno kutija</span>
                <input disabled="true" v-model="numberOfBoxes" type="number" class="form-control">
            </div>
        </div>
        <p class="total-price">Ukupna cena: ~{{totalPrice}},00 RSD</p>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    product: Object,
  },
  setup(props) {
      const price = props.product.actionPrice || props.product.price;
      const boxPrice = Math.ceil(parseFloat(props.product.characteristics['Kvadrata u kutiji']) * price);
      const numberOfSquares = ref('');
      const numberOfBoxes = ref('');
      const totalPrice = ref(0);

      const onNumberOfSquaresChange = () => {
          if(!numberOfSquares.value) {
              resetAllValues();
              return;
          }
          numberOfBoxes.value = Math.ceil(+numberOfSquares.value / parseFloat(props.product.characteristics['Kvadrata u kutiji']));
          totalPrice.value = Math.ceil(+numberOfBoxes.value * parseFloat(props.product.characteristics['Kvadrata u kutiji']) * price);
          
      }

      const resetAllValues = () => {
          numberOfSquares.value = '';
          numberOfBoxes.value = '';
          totalPrice.value = 0;
      }

      return {
          boxPrice,
          numberOfSquares,
          numberOfBoxes,
          totalPrice,
          onNumberOfSquaresChange
      }
  }
};
</script>

<style scoped lang="scss">
h4 {
    color: color(darkgrey);
    margin-bottom: 12px !important;
}

p, span{
    color: color(darkgrey);
}

p {
    
    margin-bottom: 10px;
}

span{
    margin-bottom: 5px;
}

.input-wrapper-first{
    margin-right: 10px;
}

.input-wrapper-second{
    margin-left: 10px;
}

.total-price {
    color: color(blue)!important;
    font-weight: bold;
    margin-top: 10px;
}

.tiles-calculator input {
    border: 1px solid color(darkgrey)!important;
}
</style>